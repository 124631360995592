import styled from "styled-components"

export const Content = styled.div`

  .button-primary {
  background-color: var(--green);
  border: 0;
  color: white;
  }

  .button-primary:hover {
    background-color: #255845 !important;
    border: 0;
    color: white !important;
  }
  
  .button-usg {
    font-weight: lighter;
    color: #000000;
    font-size: 0.9rem;
  }

  .button-usg:hover {
    color: var(--green) !important;
  }  
`