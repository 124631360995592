import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../../services/product'
import { 
  listProductRequest, 
  listProductSuccess, 
  listProductFailure,
  productEdit, 
  productNewFeature,
  productNewRole,
  productRoleEdit,
  productNewFeatureFailure,
  productEditFailure,
  productRoleEditFailure,
  productNewRoleFailure
} from './actions'
import { IProductState } from './types'

type GetProductAiRequest = ReturnType<typeof listProductRequest>
type PutProductEdit = ReturnType<typeof productEdit>
type PostProductNewFeature = ReturnType<typeof productNewFeature>
type PostProductNewRole = ReturnType<typeof productNewRole>
type PutProductRoleEdit = ReturnType<typeof productRoleEdit>

let token = localStorage.getItem('token')
token = token ? JSON.parse(token) : ''

function* getProductAi({ payload }: GetProductAiRequest) {
  try {
    const productList: AxiosResponse<IProductState> = yield call(
      api.get, 
      `/ai/product/${payload.productId}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )

    if(productList.data){
      yield put(listProductSuccess(productList.data))
    }

  } catch (e: any) {
    yield put(listProductFailure(e.message))
  }    
}

function* putProductEdit({ payload }: PutProductEdit) {
  try{
    const productEdit: AxiosResponse = yield call(
      api.put, 
      `/ai/product/${payload.productId}/feature/${payload.featureId}`, 
      {
        editedFeature: payload.editedFeature,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    yield put(listProductRequest(payload.productId))

  } catch (e: any) {
    yield put(productEditFailure(e.message))
  }
}

function* postProductNewFeature({ payload }: PostProductNewFeature) {
  try{
    const productNewFeature: AxiosResponse = yield call(
      api.post, 
      `/ai/product/${payload.productId}/feature/`, 
      {
        feature: payload.feature,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    yield put(listProductRequest(payload.productId))

  } catch(e: any) {
    yield put(productNewFeatureFailure(e.message))
  }
}

function* putProductRoleEdit({ payload }: PutProductRoleEdit) {
  try {
    const productRoleEdit: AxiosResponse = yield call(
      api.put, 
      `/ai/product/${payload.productId}/role/${payload.roleId}`, 
      {
        editedRole: payload.editedRole,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    yield put(listProductRequest(payload.productId))

  } catch (e: any) {
    yield put(productRoleEditFailure(e.message))
  }
}

function* postProductNewRole({ payload }: PostProductNewRole) {
  try {
    const productNewFeature: AxiosResponse = yield call(
      api.post, 
      `/ai/product/${payload.productId}/role/`, 
      {
        role: payload.role,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    yield put(listProductRequest(payload.productId))

  } catch (e: any) {
    yield put(productNewRoleFailure(e.message))
  }
}

export default all([
    takeLatest('LIST_PRODUCT_REQUEST', getProductAi),
    takeLatest('PRODUCT_EDIT', putProductEdit),
    takeLatest('PRODUCT_NEW_FEATURE', postProductNewFeature),
    takeLatest('PRODUCT_ROLE_EDIT', putProductRoleEdit),
    takeLatest('PRODUCT_NEW_ROLE', postProductNewRole)
])