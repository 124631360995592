import styled from "styled-components"
import { Button } from 'primereact/button'

export const IdeaContent = styled.div`
  @media (min-width: 900px) {
    width: 850px;
  }

  @media (max-width: 899px) {
    width: 100%;
  }

.titleForm {
  width: 100%;
  min-width: 295px;
  font-weight: 400;
  font-size: 1rem;
}

.txt {
  width: 295px;
  max-width: 295px;
  font-size: 0.7rem;
  font-weight: 700;
}

.custom-scroll {
  max-height: 280px;
  width: 100%;
  min-width: 295px;
}

.custom-scroll .p-scrollpanel-wrapper {
    border-right: 3px solid #f4f4f4;
}

.custom-scroll .p-scrollpanel-bar {
    background-color: #E2E2E2;
    opacity: 1;
    transition: background-color .3s;
}
`

export const ButtonUsg = styled(Button)`
  margin-top: 2rem;
  width: 295px;
  max-width: 295px;
  background-color: var(--green);
  border: 0;

  &.button-usg:hover {
    background-color: #255845;
    border: 0;
  }  
`