import { Card } from './styles'

export const CardReview = ({review, handler, loggedOut}: any) => {
  const lines = review.split('_NEWLINE_')
  return (
    <div className='col-12 sm:col-12 lg:col-4 md:col-6 px-0 py-1 lg:p-2 md:p-2'>
      <Card>
        <div>
          {lines.map((line: string, i: any) => (
            <p key={i} className='mb-2'>{line} <br /></p>
          ))}
        </div>   
        {!loggedOut && (
          <div 
            className="btn-review align-items-center justify-content-center"
            onClick={handler}
          >
            X DISMISS
          </div>
        )}
      </Card>
    </div>
  )
}