import { IRemoveUserStory, IReviewState } from "./types"

export function listReviewRequest(shortId: string) {
  return {
    type: 'LIST_REVIEW_REQUEST',
    payload: {
      shortId
    }
  }
}

export function listReviewSuccess(review: IReviewState) {
  return {
    type: 'LIST_REVIEW_SUCCESS',
    payload: {
      review,
    }
  }
}

export function listReviewFailure(message: string, typeError: string) {
  return {
    type: 'LIST_REVIEW_FAILURE',
    payload: {
      message,
      typeError
    }
  }
}

export function userStoryRemove({productId, featureId, groupId, storyId, shortId}: IRemoveUserStory) {
  return {
    type: 'USER_STORY_REMOVE',
    payload: {
      productId,
      featureId,
      groupId,
      storyId,
      shortId
    }
  }
}