import styled from "styled-components"
import { Button } from 'primereact/button'

export const RoleContent = styled.div`
@media (min-width: 900px) {
  width: 850px;
}

@media (max-width: 899px) {
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 1.5rem;
}

.titleForm {
  font-weight: 400;
  font-size: 1rem;
}
`

export const ButtonUsg = styled(Button)`
  margin-top: 2rem;
  width: 295px;
  max-width: 295px;
  background-color: var(--green);
  border: 0;

  &.button-usg:hover {
    background-color: #255845;
    border: 0;
  }  
`