import styled from "styled-components"

export const Content = styled.div`
width: 100%;

.input {
  margin-top: 1rem;
  width:100% ;
}

.error {
  font-size: 1rem;
  margin-top: 14px;
  color: #FF603B;
}
`