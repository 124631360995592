import { Content } from './styles'
import { RadioButton as Radio } from 'primereact/radiobutton'
import { useField } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

export const RadioButton = (props: any) => {  
  const [inputProps, meta] = useField(props)
  if(props.valuesInput === props.value) {
    localStorage.setItem('idSelectedOption', JSON.stringify(props.id))
  }

  return (
    <Content>
      <div className="field-radiobutton -mb-1 gap-2">
        <Radio
          {...inputProps}
          inputId={props.id}
          name={props.name}
          value={props.value}
          checked={props.valuesInput === props.value}
          style={{marginTop: '15px'}}
        />
        <div className='base-input col p-0 pl-3'>
          {props.inputEdit === props.id ? (
            <InputText
              className='col input-text p-0'
              value={props.inputValueEdit}
              onChange={(e) => props.setInputValueEdit(e.target.value)}
              onKeyDown={props.onkeypress}
            />
          ) : (
            <label htmlFor={props.id} style={{cursor: 'pointer'}}>
              {props.label}
            </label>
          )}
          {props.inputEdit === props.id ? (
            <Button
              icon="pi pi-check"
              className="p-button-rounded p-button-text text"
              type='button'
              onClick={props.handlerSave}
            />
          ) : (
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-text text"
              type='button'
              onClick={props.handlerEdit}
            />
          )}
        </div>
      </div>
    </Content>
  )
}