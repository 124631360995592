import styled from "styled-components"
import { Button } from 'primereact/button'

export const ReviewContent = styled.div`
@media (min-width: 900px) {
  width: 850px;
}

@media (max-width: 899px) {
  width: 100%;
}

.steps {
  width: 295px;
  max-width: 295px;  
  margin-bottom: 1rem;
}

.step-title {
  font-weight: 700;
  font-size: 0.7rem;
}

.step-subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--green);
  margin-top: 0.5rem;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 1.5rem;
}

.titleForm {
  font-weight: 400;
  font-size: 1rem;
}

.review {
  margin-top: 2rem;
}
`