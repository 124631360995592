import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalStyle } from "./styles/global";
import { Home } from "./pages/home";
import { Auth } from "./pages/auth";
import { Idea } from "./pages/steps/idea";
import { Feature } from "./pages/steps/feature";
import { Role } from "./pages/steps/role";
import { Review } from "./pages/steps/review"

import store from "./store";

import { Provider } from 'react-redux'
import RouteRequiresLogin from "./components/RouteRequiresLogin";
import { ReviewRedirect } from "./pages/reviewRedirect";
import { History } from "./pages/history";
import { HelmetProvider } from 'react-helmet-async';
import { GenericError } from "./pages/genericError";
import { About } from "./pages/about";

export function App() {

  return (
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/*" element={<Auth />} /> 
            <Route path="/idea" element={<RouteRequiresLogin component={<Idea />} />} /> 
            <Route path="/feature/:productId" element={<RouteRequiresLogin component={<Feature />} />} /> 
            <Route path="/role/:productId/:featureId" element={<RouteRequiresLogin component={<Role />} />} /> 
            <Route path="/review/*" element={<Review />} /> 
            <Route path="/" element={<RouteRequiresLogin component={<Home />} />} />
            <Route path="/:shortId" element={<ReviewRedirect />} /> 
            <Route path="/history" element={<RouteRequiresLogin component={<History />} />} />
            <Route path="/generic-error" element={<GenericError />} /> 
            <Route path="/about" element={<RouteRequiresLogin component={<About />} />} />
          </Routes>
          <GlobalStyle />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  );
}
