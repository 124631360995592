import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ReviewRedirect = () => {
  const { shortId } = useParams();

  useEffect(() => {
    window.location.href = `/review/public-link/${shortId}`
  }, [])
  
  return (
    <div></div>
  )
}

