import styled from "styled-components"

export const StepsContent = styled.div`

.steps {
  @media (min-width: 900px) {
    max-width: 240px;
  }

  @media (max-width: 899px) {
    width: 100%;
  }

  margin-bottom: 2.5rem;
}

.step-title {
  font-weight: 700;
  font-size: 0.7rem;
}

.step-subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--green);
  margin-top: 0.5rem;
}
`