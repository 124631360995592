import { Content } from './styles'
import { InputText as Input } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'
import { useField } from 'formik'

interface InputProps {
  name: string;
  id?: string;
  placeholder?: string;
  mask?: string;
  slotChar?: string;
}

export const InputText = (props: InputProps) => {
  const [inputProps, meta] = useField(props)
  const id = props.id || props.name

  return (
    <Content>
      {!props.mask ? (
        <Input
          id={id}
          placeholder={props.placeholder}
          {...inputProps}
          className={meta.error ? 'input p-invalid block': 'input'}
          autoComplete={'off'}
        />        
      ) : (
        <InputMask
          mask={props.mask}
          slotChar={props.slotChar}
          id={id}
          placeholder={props.placeholder}
          {...inputProps}
          className={meta.error ? 'input p-invalid block': 'input'}
          autoComplete={'off'}
        />    
      )}
      {meta.error && (
          <div className='error'>{meta.error.toString()}</div>
        )}
    </Content>
  )
}