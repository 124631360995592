import { Reducer } from "redux"
import { IProductState } from "./types"

const INITIAL_STATE: IProductState = {
  error: false, 
  loading: false, 
  _id: '',
  idea: '',
  user: '',
  shortId: '',
  IsUserHappyWithGeneratedCapabilities: '',
  capabilities: [],
  roles: []
}

export const product: Reducer<IProductState> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'LIST_PRODUCT_REQUEST': {
      return {...state, loading: true} 
    }
    case 'LIST_PRODUCT_SUCCESS': {
      const { product } = action.payload
      return {
        ...state,
        ...product,
        loading: false, 
        error: false 
      }
    }
    case 'LIST_PRODUCT_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    case 'PRODUCT_EDIT': {
      return {...state, loading: true} 
    }    
    case 'PRODUCT_EDIT_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    case 'PRODUCT_NEW_FEATURE': {
      return {...state, loading: true} 
    }
    case 'PRODUCT_NEW_FEATURE_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    case 'PRODUCT_ROLE_EDIT': {
      return {...state, loading: true} 
    }
    case 'PRODUCT_ROLE_EDIT_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    case 'PRODUCT_NEW_ROLE': {
      return {...state, loading: true} 
    }
    case 'PRODUCT_NEW_ROLE_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    default: {
      return state
    }
  }
}