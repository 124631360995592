import { Card } from './styles'

export const CardSample = ({sample, handler}: any) => {
  return (
    <div className='sm:col-12 lg:col-4 md:col-6'>
      <Card onClick={handler}>
        {sample}
      </Card>
    </div>
  )
}