import { Reducer } from "redux"
import { IReviewState } from "./types"

const INITIAL_STATE: IReviewState = {
  error: false,  
  loading: false,  
  idea: '',
  productId: '',
  featureId: '',
  typeError: '',
  userStoryGroupId: '',
  allRecordsDeleted: false,
  userStories: []
}

export const review: Reducer<IReviewState> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'LIST_REVIEW_REQUEST': {
      return {...state, loading: true} 
    }
    case 'LIST_REVIEW_SUCCESS': {
      const { review } = action.payload
      return {
        ...state,
        ...review,
        loading: false, 
        error: false 
      }
    }
    case 'LIST_REVIEW_FAILURE': {
      const { message, typeError } = action.payload
      return {
        ...state,
        message,
        typeError,
        loading: false, 
        error: true 
      }
    }
    case 'USER_STORY_REMOVE': {
      return {...state, loading: true}
    }
    default: {
      return state
    }
  }
}