import { Content } from './styles'
import { Button as Btn } from 'primereact/button'

interface InputProps {
  label: string;
  loading?: boolean;
  full?: boolean;
  disabled?: boolean;
  type?: string;
  onClick?: any;
}

export const ButtonSecundary = (props: InputProps) => { 

  return (
    <Content>
      {props.type === 'submit' || !props.type && (
        <Btn
          type={'submit'}
          label={props.label}
          className='p-button-lg p-button-outlined col-12'
          iconPos="right"
          loading={props.loading}
          disabled={props.disabled}
          style={{color: 'var(--green)', fontSize: '0.9rem'}}
        />
      )}

      {props.type === 'button' && (
        <Btn
          type={'button'}
          label={props.label}
          className='p-button-lg p-button-outlined col-12'
          iconPos="right"
          loading={props.loading}
          disabled={props.disabled}
          onClick={props.onClick}
          style={{color: 'var(--green)', fontSize: '0.9rem'}}
        />
      )}
    </Content>
  )
}