import { Content } from './styles'
import { Button as Btn } from 'primereact/button'

interface InputProps {
  label: string;
  loading?: boolean;
  full?: boolean;
  disabled?: boolean;
  type?: string;
  onClick?: any;
  style?: string;
}

export const Button = (props: InputProps) => { 

  return (
    <Content>
      {props.type === 'submit' || !props.type && (
        <Btn
          type={'submit'}
          label={props.label}
          className='button-usg p-button-lg col-12'
          iconPos="right"
          loading={props.loading}
          disabled={props.disabled}
        />
      )}

      {props.type === 'button' && (
        <Btn
          type={'button'}
          label={props.label}
          className={`
            ${props.style ? props.style + ' button-usg-outlined' : 'button-usg'}
            p-button-lg font-light col-12`
          }
          iconPos="right"
          loading={props.loading}
          disabled={props.disabled}
          onClick={props.onClick}
        />
      )}
    </Content>
  )
}