import {
  OtpContent,
  BaseContent,
  Content
} from './styles'
import { Footer } from "../../../components/Footer"
import { Header } from "../../../components/Header"
import { InputText } from '../../../components/Form/InputText'
import { Formik, Form } from 'formik'
import schema from './schema'
import { Button } from '../../../components/Form/Button'
import { useEffect, useState } from 'react'
import { postChallenge } from '../../../services/auth'
import { ButtonText } from '../../../components/Form/ButtonsText'

interface IAuthOtpForm {
  otpCode: string;
}

interface IChallengeParameters {
  email: string
}

interface IOtp {
  Session: string,
  ChallengeParameters: IChallengeParameters
}

export const Otp = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loadingCancelBtn, setLoadingCancelBtn] = useState(false)
  const [loginOrCreate, setLoginOrCreate] = useState<IOtp>()

  useEffect(() => {
    const local = localStorage.getItem('LoginOrCreate')

    if(local) {
      setLoginOrCreate(JSON.parse(local))
    }

  }, [])

  const initialValues: IAuthOtpForm = {
    otpCode: ''
  }

  const handleSubmit = async (values: IAuthOtpForm, errors: any) => {
    const body = {
      email: loginOrCreate?.ChallengeParameters.email,
      session: loginOrCreate?.Session,
      code: values.otpCode
    }

    setLoadingBtn(true)
    const result = await postChallenge(body);

    if(result.error) {
      errors.setFieldError('otpCode', result.error.response.data.message)
      setLoadingBtn(false)
      return
    }

    if(!result.error && !result.AccessToken) {
      errors.setFieldError('otpCode', 'Enter a valid passcode')
      setLoadingBtn(false)
      return
    }

    localStorage.setItem('token', JSON.stringify(result.AccessToken))
    localStorage.setItem('refreshToken', JSON.stringify(result.RefreshToken))

    setLoadingBtn(false)
    window.location.href = "/"
  }

  const handleOnClick = () => {
    setLoadingCancelBtn(true)
    window.location.href = "/auth"
  }

  return (
    <BaseContent>
      <Content className='flex flex-column align-items-center justify-content-center p-4 m-0'>
        <div className='col-12 p-0'>
          <Header type={2}/>
        </div>
        <OtpContent className="flex flex-column align-items-center justify-content-start">
          <div className='mt-7 mb-5 txt col-12 p-0'>
          One-time passcode
          </div>
          <div className='titleForm col-12 p-0'>
            Enter the code sent to your email
          </div>
          <div className="col-12 p-0">
            <Formik
              validationSchema={schema}
              initialValues= {initialValues}
              onSubmit={handleSubmit}       
            >
              {({ values, errors, setFieldValue }) => (
                <Form>                
                  <div>
                    <InputText
                      mask='999999'
                      slotChar=''
                      name='otpCode'
                      placeholder='6-digit code'
                    />
                  </div>
                  <div className='mt-3'>
                    <Button
                      label="Confirm"
                      loading={loadingBtn}
                      full
                    />
                  </div>          
                  <div className='mt-3'>
                    <ButtonText
                      label="Cancel" 
                      loading={loadingCancelBtn}
                      onClick={handleOnClick}
                    />
                  </div>
                </Form>
              )}
            </Formik>   
          </div>
        </OtpContent>
        <Footer />
      </Content>
    </BaseContent>
  )
}