import { Route, Routes } from "react-router-dom";
import RouteRequiresLogin from "../../../components/RouteRequiresLogin";
import { Default } from "./default"
import PublicLinkRequiresLogin from "./publicLinkRequiresLogin";
import { PublicLink } from "./public_link";

export const Review = () => {
  return (
    <Routes>
      <Route path="/:productShortId/:shortId" element={<RouteRequiresLogin component={<Default />} />} /> 
      <Route path="/public-link/:productShortId" element={<PublicLinkRequiresLogin component={<PublicLink />}/>}  /> 
    </Routes>
  )
}