import { RoleContent, ButtonUsg } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { IState } from '../../../store'
import { IRoleState } from '../../../store/modules/role/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Steps } from '../../../components/Steps'
import { Form, Formik } from 'formik'
import schema from './schema'
import { Button } from '../../../components/Form/Button'
import { RadioButton } from '../../../components/Form/RadioButton'
import { postUserStoryProps } from '../../../services/role'
import { ContentTop } from '../../../components/ContentTop'
import { SideBarLeave } from '../../../components/SideBarLeave'
import { ButtonText } from '../../../components/Form/ButtonsText'
import { RadioButtonText } from '../../../components/Form/RadioButtonText'
import { IProductState } from '../../../store/modules/product/types'
import {
  listProductRequest, 
  productNewRole, 
  productRoleEdit
} from '../../../store/modules/product/actions'
import { Loading } from '../../../components/Loading'
import { GenericError } from '../../genericError'
import { MsgErrorAI } from '../../../components/MsgErrorIA'

interface IRoleForm {
  role: string;
}

export const Role = () => {
  const { productId, featureId } = useParams();
  const dispatch = useDispatch()
  const product = useSelector<IState, IProductState>(state => state.product)

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [featureSelected, setFeatureSelected] = useState('')
  const [ideaSelected, setIdeaSelected] = useState('')
  const [visibleBottom, setVisibleBottom] = useState(false)
  const [inputEdit, setInputEdit] = useState('')
  const [inputValueEdit, setInputValueEdit] = useState('')
  const [newRole, setNewRole] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [error, setError] = useState(false)

  const stepsList = [
    {
      title: '1. IDEA',
      description: ideaSelected
    },
    {
      title: '2. FEATURE',
      description: featureSelected
    }
  ]

  const initialValues: IRoleForm = {
    role: localStorage.getItem('role') || ''
  }

  const handleSubmit = async (values: IRoleForm) => {
    if(selectedKey === 'Enter' || selectedKey === 'NumpadEnter') {
      setSelectedKey('')
      return
    }

    setLoadingBtn(true)

    const idRoleLs = localStorage.getItem('idSelectedOption') || ''

    const body = {
      productId: productId || '',
      featureId: featureId || '',
      role: values.role,
      idRole: JSON.parse(idRoleLs) || ''
    }

    const result = await postUserStoryProps(body)
    if(result.error) {     
      setError(true)
      setLoadingBtn(false)
      return
    }

    localStorage.removeItem('idSelectedOption')
    localStorage.setItem('role', values.role)
    localStorage.setItem('idRole', idRoleLs)
    setLoadingBtn(false)
    window.location.href = `/review/${product.shortId}/${result.shortId}`
  }  

  useEffect(() => {
    const featureLs = localStorage.getItem('feature') || ''
    setFeatureSelected(featureLs)

    const idea = localStorage.getItem('idea') || ''
    setIdeaSelected(idea)

    if(productId) dispatch(listProductRequest(productId))
  }, [])  

  useEffect(() => {
    if(product.error) setError(true)
  }, [product])

  const handleLeave = () => {
    window.location.href = `/`;
  }

  const handlerBack = () => {
    window.location.href = `/feature/${productId}`;
  }  

  const handlerEdit = (id: string, value: string) => {
    setInputEdit(id)
    setInputValueEdit(value)
  }

  const handlerSave = async (values: IRoleForm) => {
    const body = {
      productId: productId || '',
      roleId: inputEdit,
      editedRole: inputValueEdit
    }

    dispatch(productRoleEdit(body))

    values.role = inputValueEdit

    setInputEdit('')
    setInputValueEdit('')
  }

  const handlerNewRole = async (values: IRoleForm) => {
    const body = {
      productId: productId || '',
      role: newRole
    }

    dispatch(productNewRole(body))
    values.role = newRole

    setNewRole('')
  }

  const enter = (e: React.KeyboardEvent<HTMLInputElement>, values: IRoleForm) => {
    const key = e.code
    
    if(key === 'Escape') {
      setInputEdit('')
    } else if(key === 'Enter' || key === 'NumpadEnter') {
      handlerSave(values)
    }
  }

  const enterRadioButtonText = (e: React.KeyboardEvent<HTMLInputElement>, values: IRoleForm) => {  
    values.role = 'customized'
    if(newRole.length === 0) return 
    
    const key = e.code

    if(key === 'Escape') {
      setNewRole('')
    } else if(key === 'Enter' || key === 'NumpadEnter') {
      handlerNewRole(values)
    }
  }

  return (
    <div>
      {product.loading && ( 
        <Loading /> 
      )} 
      {error ? (
        <GenericError />
      ) : (
        <div className='flex flex-column align-items-center justify-content-center p-4 m-0'>        
          <RoleContent className="flex flex-column align-items-center justify-content-center">
            <ContentTop
              handleClick={() => setVisibleBottom(true)}
            />
            <Steps stepsList={stepsList}/>
            <div className='title col-12 p-0'>
              3. Role
            </div>
            <div className='titleForm col-12 p-0 mt-5'>
              Enter a type of persona or role who would use this feature.
            </div>
            <div className='titleForm col-12 p-0 mt-5 mb-4'>
              <strong>Select a role:</strong>
            </div>
            <Formik
              validationSchema={schema}
              initialValues= {initialValues}
              onSubmit={handleSubmit}       
            >
              {({ values, errors, setFieldValue }) => (
                <Form className='col-12 p-0' onKeyDown={(e) => setSelectedKey(e.code)}> 
                  {product.roles.length > 0 ? (
                    <>
                      {product.roles.map(rl => (
                        <RadioButton
                          key={rl._id}
                          id={rl._id}
                          name='role'
                          value={rl.role}
                          label={rl.role}
                          valuesInput={values.role}
                          handlerEdit={() => handlerEdit(rl._id, rl.role)}
                          handlerSave={() => handlerSave(values)}
                          inputEdit={inputEdit}
                          setInputValueEdit={setInputValueEdit}
                          inputValueEdit={inputValueEdit}
                          setFieldValue={setFieldValue}
                          onkeypress={(e: React.KeyboardEvent<HTMLInputElement>) => enter(e, values)}
                        />
                      ))}
                    </>
                  ): (
                    <div className={`${product.loading ? 'hidden' : ''}`}>
                      <MsgErrorAI step={'roles'} />
                    </div>
                  )}                     
                  <RadioButtonText
                    name='role'
                    valueText={newRole}
                    setNewFeature={setNewRole}
                    valuesInput={values.role}
                    placeholder='+ Another Role'
                    handler={() => handlerNewRole(values)}   
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => enterRadioButtonText(e, values)}
                  />
                  <div className='flex flex-wrap flex-row-reverse justify-content-center mt-5 lg:mt-7 md:mt-7 sm:mt-5 col-12 p-0'>
                    <div
                      className='col sm:col lg:col-fixed md:col-fixed p-0'
                      style={{minWidth: '295px'}}
                    >
                      <Button
                        label="Next"
                        type='button'
                        loading={loadingBtn}
                        onClick={() => handleSubmit(values)}
                        disabled={product.roles.findIndex(f => f.role === values.role) === -1}
                      />
                    </div>
                    <div
                      className='col sm:col lg:col-fixed md:col-fixed p-0 mt-3 sm:mt-0 md:mt-0 lg:mt-0'
                      style={{minWidth: '295px'}}
                    >
                      <ButtonText
                        label="Back"
                        onClick={() => handlerBack()}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </RoleContent>
        </div>
      )}      
      <SideBarLeave
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        handleLeave={handleLeave}
        loadingBtn={loadingBtn}
       />
    </div>
  )
}