import { useState } from 'react'
import { ButtonText } from '../../components/Form/ButtonsText'
import {
  Content,
  ButtonUsg
} from './styles'

export const GenericError = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)

  return (
    <Content>
      <div className='
        flex flex-column
        lg:align-items-center md:align-items-center sm:align-items-center align-items-start
        lg:col-6 md:col-6 sm:col-8 col p-0'
      >
        <i className="pi pi-exclamation-circle icon"></i>
        <div className='title-error'>An error occurred</div>
        <div className='
          flex flex-column
          lg:align-items-center md:align-items-center sm:align-items-center align-items-start
          col p-0 mt-8'>
          <p>It seems our AI is misbehaving and does not want to be contacted.</p>
          <p className='my-3'>We apologize on its behalf.</p>         
          <p>Please try again later or <span className='contact'>contact us.</span></p>
        </div>
        <div className='
          flex flex-wrap flex-row-reverse 
          justify-content-center mt-8 
          lg:col-8 md:col-8 sm:col-12 col-12 p-0 gap-3'>
          <div
            className='col-12 lg:col md:col sm:col p-0'
          >
            <ButtonUsg 
              label="Try again"
              className='col-12 button-outlined-usg p-button-outlined p-button-lg'
              style={{
                border: '1px solid #45A582', 
                color: '#45A582',
                fontSize: '0.9rem'
              }}
              onClick={() => window.location.reload()}
            />
          </div>
          <div
            className='col-12 lg:col md:col sm:col p-0'
          >
            <ButtonText
              label="Back"
              onClick={() => window.history.back()}
            />
          </div>
        </div>
      </div>
    </Content>
  )
}