import { FeatureContent } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { listProductRequest, productEdit, productNewFeature } from '../../../store/modules/product/actions'
import { IState } from '../../../store'
import { IProductState } from '../../../store/modules/product/types'
import { useParams } from 'react-router-dom'
import { Steps } from '../../../components/Steps'
import { RadioButton } from '../../../components/Form/RadioButton'
import { Formik, Form } from 'formik'
import schema from './schema'
import { Button } from '../../../components/Form/Button'
import { ContentTop } from '../../../components/ContentTop'
import { SideBarLeave } from '../../../components/SideBarLeave'
import { ButtonText } from '../../../components/Form/ButtonsText'
import { Loading } from '../../../components/Loading' 
import { RadioButtonText } from '../../../components/Form/RadioButtonText'
import { MsgErrorAI } from '../../../components/MsgErrorIA'
import { GenericError } from '../../genericError'

interface IFeature {
  feature: string;
  text: string;
}

export const Feature = () => {
  const { productId } = useParams();
  const dispatch = useDispatch()
  const product = useSelector<IState, IProductState>(state => state.product)

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [visibleBottom, setVisibleBottom] = useState(false)
  const [inputEdit, setInputEdit] = useState('')
  const [inputValueEdit, setInputValueEdit] = useState('')
  const [newFeature, setNewFeature] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [error, setError] = useState(false)

  const stepsList = [
    {
      title: '1. IDEA',
      description: product.idea
    }
  ]
  
  const initialValues: IFeature = {
    feature: localStorage.getItem('feature') || '',
    text: ''
  }

  useEffect(() => {
    if(productId) dispatch(listProductRequest(productId))
  }, [])

  useEffect(() => {
    if(product.error) setError(true)
  }, [product])

  const handleSubmit: any = async (values: IFeature) => {
    if(selectedKey === 'Enter' || selectedKey === 'NumpadEnter') {
      setSelectedKey('')
      return
    }

    setLoadingBtn(true)
    let selectIdFeature = localStorage.getItem('idSelectedOption')
    
    if(selectIdFeature) selectIdFeature = JSON.parse(selectIdFeature)

    localStorage.removeItem('idSelectedOption')
    localStorage.setItem('feature', values.feature)
    localStorage.setItem('idea', product.idea)
    setLoadingBtn(false)

    window.location.href = `/role/${productId}/${selectIdFeature}`
  }

  const handleLeave = () => {
    window.location.href = `/`;
  }

  const handlerEdit = (id: string, value: string) => {
    setInputEdit(id)
    setInputValueEdit(value)
  }

  const handlerSave = async (values: IFeature) => {
    const body = {
      productId: productId || '',
      featureId: inputEdit,
      editedFeature: inputValueEdit
    }

    dispatch(productEdit(body))

    values.feature = inputValueEdit

    setInputEdit('')
    setInputValueEdit('')
  }

  const handlerNewFeature = async (values: IFeature) => {
    const body = {
      productId: productId || '',
      feature: newFeature
    }

    dispatch(productNewFeature(body))
    values.feature = newFeature

    setNewFeature('')
  }

  const enter = (e: React.KeyboardEvent<HTMLInputElement>, values: IFeature) => {
    const key = e.code

    if(key === 'Escape') {
      setInputEdit('')
    } else if(key === 'Enter' || key === 'NumpadEnter') {
      handlerSave(values)
    }
  }

  const enterRadioButtonText = (e: React.KeyboardEvent<HTMLInputElement>, values: IFeature) => {  
    values.feature = 'customized'
    if(newFeature.length === 0) return  

    const key = e.code

    if(key === 'Escape') {
      setNewFeature('')
    } else if(key === 'Enter' || key === 'NumpadEnter') {
      handlerNewFeature(values)
    }
  }

  return (
    <div>
      {product.loading && ( 
        <Loading /> 
      )} 
      {error ? (
        <GenericError />
      ) : (        
        <div className='flex flex-column align-items-center justify-content-center p-4 m-0'>        
          <FeatureContent className="flex flex-column align-items-center justify-content-center">
            <ContentTop 
              handleClick={() => setVisibleBottom(true)}
            />
            <Steps stepsList={stepsList}/>
            <div className='title col-12 p-0'>
              2. Feature
            </div>
            <div className='titleForm col-12 p-0 mt-5'>
              Our AI has generated some features based on your idea.
            </div>
            <div className='titleForm col-12 p-0 mt-5 mb-4'>
              <strong>Select a feature:</strong>
            </div>
            <Formik
              validationSchema={schema}
              initialValues = {initialValues}
              onSubmit={(values) => handleSubmit(values)}    
            >
              {({ values, errors, setFieldValue }) => (
                <Form className='col-12 p-0' onKeyDown={(e) => setSelectedKey(e.code)}>  
                  {product.capabilities.length > 0 ? (
                    <>
                    {product.capabilities.map(capabilitie => (
                      <RadioButton
                        key={capabilitie._id}
                        id={capabilitie._id}
                        name='feature'
                        value={capabilitie.capability}
                        label={capabilitie.capability}
                        valuesInput={values.feature}
                        handlerEdit={() => handlerEdit(capabilitie._id, capabilitie.capability)}
                        handlerSave={() => handlerSave(values)}
                        inputEdit={inputEdit}
                        setInputValueEdit={setInputValueEdit}
                        inputValueEdit={inputValueEdit}
                        setFieldValue={setFieldValue}
                        onkeypress={(e: React.KeyboardEvent<HTMLInputElement>) => enter(e, values)}
                      />
                    ))}
                    </>
                  ) : (
                    <div className={`${product.loading ? 'hidden' : ''}`}>
                      <MsgErrorAI step={'features'} />
                    </div>
                  )}
                  <RadioButtonText
                    name='feature'
                    valueText={newFeature}
                    setNewFeature={setNewFeature}
                    valuesInput={values.feature}
                    placeholder='+ Another feature'
                    handler={() => handlerNewFeature(values)}   
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => enterRadioButtonText(e, values)}
                  />
                  <div className='flex flex-wrap flex-row-reverse justify-content-center mt-5 lg:mt-7 md:mt-7 sm:mt-5 col-12 p-0'>
                    <div
                      className='col sm:col lg:col-fixed md:col-fixed p-0'
                      style={{minWidth: '295px'}}
                    >
                      <Button
                        label="Next"
                        type='button'
                        loading={loadingBtn}
                        onClick={() => handleSubmit(values)}
                        disabled={product.capabilities.findIndex(f => f.capability === values.feature) === -1}
                      />
                    </div>
                    <div
                      className='col sm:col lg:col-fixed md:col-fixed p-0 mt-3 sm:mt-0 md:mt-0 lg:mt-0'
                      style={{minWidth: '295px'}}
                    >
                      <ButtonText
                        label="Back"
                        onClick={() => window.location.href = `/idea`}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>           
          </FeatureContent>
        </div>
      )}
      <SideBarLeave
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        handleLeave={handleLeave}
        loadingBtn={loadingBtn}
       />
    </div>
  )
}