import styled from "styled-components"

export const Card = styled.div`
@media (max-width: 575px) {
  margin-bottom: 0.6rem ;
  min-width: 100%;
}

@media (min-width: 991px) {
  min-height: 230px;
}

@media (max-width: 990px) {
  min-height: 170px;
}

@media (max-width: 766px) {
  min-height: 110px;
}

padding: 1rem;
background-color: #F4F4F4;
border-radius: 4px;
font-size: 1rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: flex ;
flex-direction: column ;
justify-content: space-between ;

.btn-review {
color: var(--green);
font-size: 0.9rem;
font-weight: 500;
text-align:center;
cursor: pointer;
}

.btn-review:hover {
  color: #255845 !important;
}
`