import { Card } from './styles'
import moment from 'moment'

interface IProps {
  idea: string,
  userStoriesCount: number,
  createdAt: string,
  handler: any
}

export const CardHistory = ({idea, userStoriesCount, createdAt, handler}: IProps) => {
  return (
    <Card onClick={handler}>
      <div className="mb-0">
        {idea}
        <p className='btn-review mt-3'>
          {userStoriesCount} user stories
        </p>
      </div> 
      <div className="txt-log align-items-center justify-content-center col-12 p-0 text-center">
        {moment(createdAt).format('DD MMM YYYY h:mm:ss A')}
      </div>
    </Card>
  )
}