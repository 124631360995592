import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CardReview } from '../../../../components/CardReview'
import { Footer } from '../../../../components/Footer'
import { Header } from '../../../../components/Header'
import { SideBarRecommend } from '../../../../components/SideBarRecommend'
import { useMedia } from '../../../../Hooks/useMedia'
import { IState } from '../../../../store'
import { listReviewRequest } from '../../../../store/modules/review/actions'
import { IReviewState } from '../../../../store/modules/review/types'
import { Divider } from 'primereact/divider';
import { 
  ReviewContent,
  ButtonUsg,
  BaseContent,
  Content,
  ButtonOutlinedUsg
} from './styles'
import { listProductIdeaRequest, userStoryRemove } from '../../../../store/modules/productIdea/actions'
import { Loading } from '../../../../components/Loading' 
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { IProductIdeaState } from '../../../../store/modules/productIdea/types'

export const PublicLinkLoggedOut = () => {
  const mobile = useMedia('(max-width: 40rem)')
  const { productShortId } = useParams();
  const dispatch = useDispatch()
  const productIdea = useSelector<IState, IProductIdeaState>(state => state.productIdea) 

  const [visibleBottom, setVisibleBottom] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [existUserStories, setExistUserStories] = useState(false)

  const Exception = () => {
    return (
      <div 
        className='
        flex flex-column
        align-items-start lg:align-items-center md:align-items-center sm:align-items-center
        text-start lg:text-center md:text-center sm:text-center
        mt-6 p-0 col-12'
      >
        <i className={`pi pi-exclamation-circle icon ${productIdea.loading ? 'hidden' : ''}`} style={{fontSize: '1.5rem'}}></i>
        <div>
          <p className={`mt-2 ${productIdea.loading ? 'hidden' : ''}`}>You do not have user stories saved yet.</p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if(productShortId) dispatch(listProductIdeaRequest(productShortId))
  }, [])

  useEffect(() => {
    setExistUserStories(false)
    for(let t of productIdea.usgs) {
      for(let f of t.userStories) {
        if(f.isDeleted === false) setExistUserStories(true)
      }
    }
  }, [productIdea])

  const handleStart = () => {
    window.location.href = `/idea`;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            User Story Generator: AI helps you write user stories | Planorama
            Labs
          </title>
          <meta name='description' content={productIdea.idea} />
          <link
            rel='canonical'
            href={`${process.env.REACT_APP_DOMAIN}/review/public-link/${productShortId}`}
          />

          <meta property='og:description' content={productIdea.idea} />
          <meta
            property='og:title'
            content='User Story Generator: AI helps you write user stories | Planorama Design'
          />

          <meta name='twitter:description' content={productIdea.idea} />
          <meta
            name='twitter:title'
            content='User Story Generator: AI helps you write user stories | Planorama Design'
          />
        </Helmet>
      </HelmetProvider>
      <BaseContent>
        {productIdea.loading && <Loading />}
        <Content className='flex-column align-items-center justify-content-center px-5 py-3 m-0'>
          <div className='mb-7 col-12'>
            <Header type={3} />
          </div>
          <ReviewContent className='flex flex-column align-items-center justify-content-center'>
            <div className='txt col-12 p-0'>PRODUCT IDEA</div>
            <div className='title col-12 p-0'>{productIdea.idea}</div>
            <div className='txt mt-6 col-12 p-0'>USER STORIES</div>
            <div className='review flex flex-wrap col-12 p-0 justify-content-start'>
              {productIdea.usgs.length > 0 ? (
                <>
                  {existUserStories ? (
                    <>
                      {productIdea.usgs.map((itemUsgs) =>
                        itemUsgs.userStories
                          .filter((f) => f.isDeleted != true)
                          .map((us) => (
                            <CardReview
                              key={us._id}
                              review={us.capability}
                              loggedOut
                            />
                          ))
                      )}
                    </>
                  ) : (
                    <Exception />
                  )}
                </>
              ) : (
                <Exception />
              )}
            </div>
            <div className='grid col-12 gap-3 p-0'>
              {/* <div className='lg:col md:col sm:col-12 col-12 m-0 p-0'>
                {!mobile && (
                  <div className='titleBtn text-center'>
                    Show others
                  </div>
                )}
                <div>
                  <ButtonUsg 
                    label="Share these results"
                    className='button-usg col-12 p-button-lg'
                    style={{border: '1px solid #45A582', color: '#45A582'}}
                    onClick={() => setVisibleBottom(true)}
                  />
                </div>
              </div> */}
              <div className='xl:hidden lg:hidden md:hidden sm:hidden col-12 mt-4'>
                <Divider />
              </div>
              <div className='xl:hidden lg:hidden md:hidden sm:hidden col-12 p-0'>
                <div className='titleBtn text-center'>Try it yourself</div>
                <div>
                  <ButtonOutlinedUsg
                    label='Start a new product idea'
                    className='button-outlined-usg p-button-outlined col-12 p-button-lg'
                    style={{ border: '1px solid #45A582', color: '#45A582' }}
                    onClick={handleStart}
                  />
                </div>
              </div>
            </div>
          </ReviewContent>
          <div className='col-12'>
            <Footer />
          </div>
        </Content>
      </BaseContent>
      <SideBarRecommend
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        loadingBtn={loadingBtn}
        shortId={productShortId || ''}
      />
    </>
  )
}