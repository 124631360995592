import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../../services/review'
import { 
  listReviewSuccess,
  listReviewRequest,
  listReviewFailure,
  userStoryRemove
} from './actions'
import { IReviewState } from './types'

type GetReviewRequest = ReturnType<typeof listReviewRequest>
type DeleteUserStoryRemove = ReturnType<typeof userStoryRemove>

let token = localStorage.getItem('token')
token = token ? JSON.parse(token) : ''

function* getReview({ payload }: GetReviewRequest) {
  const { shortId } = payload

  try{ 
    const reviewList: AxiosResponse<IReviewState> = yield call(
      api.get, 
      `/s/${shortId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )

    const undeletedRecords = reviewList.data.userStories.filter(us => us.isDeleted === false)
  
    if(reviewList.data){
      const review: IReviewState = {
        error: false,
        loading: false,
        typeError: '',
        idea: reviewList.data.idea,
        productId: reviewList.data.productId,
        featureId: reviewList.data.featureId,
        userStoryGroupId: reviewList.data.userStoryGroupId,
        userStories: undeletedRecords,
        allRecordsDeleted: undeletedRecords.length > 0 ? false : true
      }
  
      yield put(listReviewSuccess(review))
    }
  } catch (e: any) {
    yield put(listReviewFailure(e.message, e.type))
  }  
}

function* deleteUserStory({ payload }: DeleteUserStoryRemove) {
  const { productId, featureId, groupId, storyId, shortId } = payload

  const result: AxiosResponse = yield call(
    api.delete,
    `/ai/product/${productId}/feature/${featureId}/group/${groupId}/userstory/${storyId}`,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )

  yield put(listReviewRequest(shortId))
}

export default all([
    takeLatest('LIST_REVIEW_REQUEST', getReview),
    takeLatest('USER_STORY_REMOVE', deleteUserStory)
])