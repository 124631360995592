import {
  DefaultContent,
  BaseContent,
  Content
} from './styles'
import { Footer } from "../../../components/Footer"
import { Header } from "../../../components/Header"
import { InputText } from '../../../components/Form/InputText'
import { Formik, Form } from 'formik'
import schema from './schema'
import { Button } from '../../../components/Form/Button'
import { postLoginOrCreate } from '../../../services/auth'
import { useState } from 'react'
import { GenericError } from '../../genericError'

interface IAuthForm {
  email: string;
}

export const Default = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [error, setError] = useState(false)

  const initialValues: IAuthForm = {
    email: '',
  }

  const handleSubmit = async (values: IAuthForm) => {
    setLoadingBtn(true)
    const result = await postLoginOrCreate(values);
    if(result.error) {
      setError(true)
      setLoadingBtn(false)
      return
    }
    
    localStorage.setItem(
      'LoginOrCreate',
      JSON.stringify(result)
    )

    setLoadingBtn(false)
    window.location.href = "/auth/otp";
  }

  return (
    <>
      {error ? (
        <GenericError />
      ) : (
        <BaseContent>
          <Content className='flex-column align-items-center justify-content-center px-5 pb-3 m-0'>
            <div className='col-12 m-0 p-0'>
              <Header />
            </div>
            <DefaultContent className='flex flex-column align-items-center justify-content-center'>
              <div className='txt col-12 p-0'>
                <p className='mb-3'>
                  Agile teams capture product requirements as user stories.
                </p>
                <p className='mb-3'>
                  <strong>Planorama Design</strong> built this free app to help
                  consider a product concept and its potential features, user
                  personas, and story narratives.
                </p>
                <div className='link-example mb-3 w-max'>
                  <p>
                    <a href='https://link.planorama.design/usg/prod-sample-stories'>
                      View example results
                    </a>
                  </p>
                  <i className='pi pi-external-link icon ml-2 text-xs'></i>
                </div>
                <p>
                  While not meant to replace requirements gathering, this tool
                  can broaden the problem space and aid in UX research. Enjoy!
                </p>
              </div>
              <div className='titleForm col-12 p-0 text-center md:text-left lg:text-left xl:text-left sm:text-left'>
                Let’s get started
              </div>
              <div>
                <p className='my-3 subTitle'>
                  Please enter a valid email, and we will send you a one-time
                  passcode to log in.
                </p>
                <p className='info'>
                  We won’t send marketing emails or sell your info.
                </p>
              </div>
              <div className='col-12 p-0 m-0'>
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, setFieldValue }) => (
                    <Form>
                      <InputText name='email' placeholder='Email' />
                      <div className='mt-3'>
                        <Button
                          label='Start'
                          loading={loadingBtn}
                          full
                          disabled={!values.email || !!errors.email}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </DefaultContent>
            <Footer />
          </Content>
        </BaseContent>
      )}
    </>
  )
}