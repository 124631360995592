import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../../services/review'
import { listHistorySuccess, listHistoryRequest, listHistoryFailure } from './actions'
import { IHistoryState } from './types'

type GetHistoryRequest = ReturnType<typeof listHistoryRequest>

let token = localStorage.getItem('token')
token = token ? JSON.parse(token) : ''

function* getHistory() {

  try {
    const historyList: AxiosResponse<IHistoryState> = yield call(
      api.get, 
      `/ai/product`,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    if(historyList.data){
      yield put(listHistorySuccess(historyList.data))
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('LoginOrCreate')
      window.location.href = `/auth`;
    }

    listHistoryFailure()
  }
}

export default all([
    takeLatest('LIST_HISTORY_REQUEST', getHistory)
])