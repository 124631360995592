import styled from "styled-components"

export const Content = styled.div`
  .button-usg {
  background-color: var(--green);
  font-size: 0.9rem;
  border-color: var(--green);
  }

  .button-usg:hover {
    background-color: #255845 !important;
  border-color: #255845;
  }  

  .button-usg:focus {
    outline: none !important;
  } 

  .button-usg-outlined {
  font-size: 0.9rem;
  border-color: var(--green);
  color: var(--green);
  }

  .button-usg-outlined:hover {
    color: var(--green) !important;
    border-color: #255845;
  }  

  .button-usg-outlined:focus {
    outline: none !important;
  } 
`