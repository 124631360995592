import { Reducer } from "redux"
import { IProductIdeaState } from "./types"

const INITIAL_STATE: IProductIdeaState = {
  error: false, 
  loading: false, 
  productId: '',
  idea: '',
  usgs: []
}

export const productIdea: Reducer<IProductIdeaState> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'LIST_PRODUCT_IDEA_REQUEST': {
      return {...state, loading: true} 
    }
    case 'LIST_PRODUCT_IDEA_SUCCESS': {
      const { productIdea } = action.payload
      return {
        ...state,
        ...productIdea,
        loading: false, 
        error: false 
      }
    }
    case 'LIST_PRODUCT_IDEA_FAILURE': {
      const { message } = action.payload
      return {
        ...state,
        message,
        loading: false, 
        error: true 
      }
    }
    case 'USER_STORY_PRODUCT_IDEA_REMOVE': {
      return {...state, loading: true}
    }
    default: {
      return state
    }
  }
}