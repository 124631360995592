import styled from "styled-components"

export const BaseContent = styled.div`
display: flex ;
justify-content: center ;
`

export const Content = styled.div`
@media (min-width: 900px) {
  width: 850px;
}

@media (max-width: 899px) {
  width: 100%;
}
`

export const ReviewContent = styled.div`
width: 100%;

.txt {
  color: var(--green);
  font-weight: 700;
  font-size: 0.9rem;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 1rem;
}

.review {
  margin-top: 1rem;
}
`