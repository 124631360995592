import { useEffect, useState } from "react"
import { decodeToken, isExpired } from 'react-jwt'
import { postAuthRefresh } from "../services/auth";

export const useToken = () => {
  const [token, setToken] = useState(false);

  const tokenLs = localStorage.getItem('token') || ''
  const refreshTokenLs = localStorage.getItem('refreshToken') || ''

  const _decodeToken = decodeToken(tokenLs)
  const _isExpired = isExpired(tokenLs)

  useEffect(() => {
    
    if(tokenLs) {

      if(_isExpired) {
        const newToken = async () => {
          const result = await postAuthRefresh(refreshTokenLs)

          if(result.error) {
            setToken(false)
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('LoginOrCreate')
            return
          }
          
          localStorage.setItem('token', JSON.stringify(result.accessToken))
          localStorage.setItem('refreshToken', JSON.stringify(result.refreshToken))
          setToken(true)
          return
        }

        newToken()
      }

      setToken(true)
      return
    }

    setToken(false)
  }, []) 

  return token
}