import styled from "styled-components"

export const Content = styled.div`

@media (min-width: 900px) {
  width: 850px;
}

@media (max-width: 899px) {
  width: 100%;
}

.input {
  margin-top: 1rem;
}

.error {
  font-size: 1rem;
  margin-top: 14px;
  color: #FF603B;
}

.radio-label {
  width: 100%;
  padding: 1rem;
  border: 1px solid #000 ;
  border-radius: 4px;
}

.radio-label-error {
  width: 100%;
  padding: 1rem;
  border: 1px solid red ;
  border-radius: 4px;
}

.base-input {
  display: flex ;
  align-items: center ;
  justify-content: space-between ;
  border: 1px solid #000;
  border-radius:4px;
  margin-top: 1rem;
}

.text {
  color:  #000;
}

.text:hover {
  color:  #000 !important;
}

.input-text {
  border: 0px;
}
`