import { Button } from "primereact/button"
import styled from "styled-components"

export const Content = styled.div`
display: flex ;
justify-content: center ;
padding:1rem ;

.icon {
  font-size: 4rem;
  color: var(--green) ;
  margin-top: 80px;
}

.title-error {
  color: var(--green);
  font-size: 2rem;
  margin-top: 1.5rem;
}

.contact {
  cursor: pointer;
  color: var(--green);
}

.contact:hover {
  text-decoration: underline;
}
`

export const ButtonUsg = styled(Button)`
  background-color: var(--green);
  border: 0;
  font-size: 0.9rem;

  &.button-usg:hover {
    background-color: #255845;
    border: 0;
  }
`