import { Contenttop } from './styles'
import { Button} from 'primereact/button'

interface IProps {
  contentTitle?: string;
  handleClick?: any;
}

export const ContentTop = ({contentTitle, handleClick}: IProps) => {
  
  return (
    <>
      <Contenttop className="flex flex-column align-items-center justify-content-center">
        <div className='flex justify-content-between col-12 m-0 p-0'>  
          <div className='title'>
            {contentTitle}
          </div>          
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text p-button-plain"
            aria-label="Filter"
            onClick={handleClick}
          />          
        </div>
      </Contenttop>
    </>    
  )
}