import { useEffect, useState } from "react"

export const useMedia = (media: string) => {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const { matches } = window.matchMedia(media);
    setMatch(matches);
  }, [])

  useEffect(() => {
    function changeMatch() {
      const { matches } = window.matchMedia(media);
      setMatch(matches);
    }

    window.addEventListener('resize', changeMatch)
    return () => {
      window.removeEventListener('resize', changeMatch)
    }
  }, [media])

  return match
}