import { Route, Routes } from "react-router-dom";
import { Default } from "./default";
import { Otp } from "./otp";

export const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Default />} /> 
      <Route path="otp" element={<Otp />} /> 
    </Routes>
  )
}