import { api } from './api'

export const postLoginOrCreate = async (email: any) => {
  try {
    const { data } = await api.post('/auth/loginOrCreate', email)
    return data
  } catch (err) {
    return {error: err, msg: 'Tente novamente mais tarde!'}
  }
}

export const postAuthRefresh = async (refreshToken: string) => {
  const refresh = JSON.parse(refreshToken)
  try {
    const { data } = await api.post('/auth/refresh', {refreshToken: refresh})
    return data
  } catch (err) {
    return {error: err, msg: 'Tente novamente mais tarde!'}
  }
}

export const postChallenge = async (body: any) => {
  try {
    const { data } = await api.post('/auth/challenge', body)
    return data
  } catch (error) {
    return {error}
  }
}

export const postAiProduct = async (idea: any) => {
  let token = localStorage.getItem('token')
  if(token) token = JSON.parse(token)

  try {
    const { data } = await api.post('/ai/product', idea,
    {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    return data
  } catch (err) {
    return {error: err, msg: 'Tente novamente mais tarde!'}
  }
}