import { 
  IProductState, 
  IProductEditProps, 
  IProductNewFeatureProps, 
  IProductRoleEditProps, 
  IProductNewRoleProps 
} from "./types"

export function listProductRequest(productId: string) {
  return {
    type: 'LIST_PRODUCT_REQUEST',
    payload: {
      productId,
    }
  }
}

export function listProductSuccess(product: IProductState) {
  return {
    type: 'LIST_PRODUCT_SUCCESS',
    payload: {
      product,
    }
  }
}

export function listProductFailure(message: string) {
  return {
    type: 'LIST_PRODUCT_FAILURE',
    payload: {
      message,
    }
  }
}

export function productEdit({productId, featureId, editedFeature}: IProductEditProps) {
  return {
    type: 'PRODUCT_EDIT',
    payload: {
      productId,
      featureId,
      editedFeature
    }
  }
}

export function productEditFailure(message: string) {
  return {
    type: 'PRODUCT_EDIT_FAILURE',
    payload: {
      message
    }
  }
}

export function productNewFeature({productId, feature}: IProductNewFeatureProps) {
  return {
    type: 'PRODUCT_NEW_FEATURE',
    payload: {
      productId,
      feature
    }
  }
}

export function productNewFeatureFailure(message: string) {
  return {
    type: 'PRODUCT_NEW_FEATURE_FAILURE',
    payload: {
      message
    }
  }
}

export function productRoleEdit({productId, roleId, editedRole}: IProductRoleEditProps) {
  return {
    type: 'PRODUCT_ROLE_EDIT',
    payload: {
      productId,
      roleId,
      editedRole
    }
  }
}

export function productRoleEditFailure(message: string) {
  return {
    type: 'PRODUCT_ROLE_EDIT_FAILURE',
    payload: {
      message
    }
  }
}

export function productNewRole({productId, role}: IProductNewRoleProps) {
  return {
    type: 'PRODUCT_NEW_ROLE',
    payload: {
      productId,
      role
    }
  }
}

export function productNewRoleFailure(message: string) {
  return {
    type: 'PRODUCT_NEW_ROLE_FAILURE',
    payload: {
      message
    }
  }
}