import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../../services/role'
import { listRoleRequest, listRoleSuccess } from './actions'
import { IRoleState } from './types'

type GetRoleRequest = ReturnType<typeof listRoleRequest>

let token = localStorage.getItem('token')
token = token ? JSON.parse(token) : ''

function* getRole({ payload }: GetRoleRequest) {
  const { productId, featureId, groupId } = payload

  const roleList: AxiosResponse<IRoleState> = yield call(
    api.get, 
    `/ai/product/${productId}/feature/${featureId}/group/${groupId}`,
    {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  )

  if(roleList.data){
    yield put(listRoleSuccess(roleList.data))
  }
}

export default all([
    takeLatest('LIST_ROLE_REQUEST', getRole)
])