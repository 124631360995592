import { Nav } from '../../components/Nav'
import Matt from '../../assets/Matt-Genovese.png'
import {  
  BaseContent,
  Content
} from './styles'

export const About = () => {

  return (
    <BaseContent>
      <Content>
        <div className="m-0 col-12 p-0">
          <Nav logo type={2}/>
        </div> 
        <div className='title'>About</div>
        <div className='mt-7 col p-0'>
          <p className='mb-5'>
            <i>User stories</i> are a natural language description of a particular user’s experience as s/he
            accomplishes an objective in the product. Stories often become the internal requirements
            documentation that enable UX designers and developers in Agile teams to collaborate and build
            the feature described in the story.
          </p>

          <p className='mb-5'>
            User stories normally begin with a narrative written from a single user’s perspective that
            elaborates the need, and serves as the basis for the remainder of the requirements:
          </p>

          <p className='mb-3'><strong>As a</strong> {'<'}type of user or persona{'>'}</p>
          <p className='mb-3'><strong>I want to</strong> {'<'}achieve an objective{'>'}</p>
          <p className='mb-5'><strong>So that I can</strong> {'<'}reasoning or motivation{'>'}</p>

          <p className='mb-5'>
            We built User Story Generator to help you brainstorm features for your product, and get you past
            writers’ block (which happens to all of us!).
          </p>

          <p>
            After the narrative, the story contains a set of acceptance criteria which are the conditions
            which must be satisfied for the feature to be considered complete.
          </p>
        </div>
        <div className='col my-7 card p-0'>
          <div className='lg:flex md:flex sm:flex flex py-5 px-4 gap-5 flex-wrap align-items-center justify-content-center'>
            <img src={Matt} alt='Matt Genovese' />
            <div className='
              col-12 lg:col md:col sm:col'
            >
              For more information about narratives, acceptance criteria, and writing user stories in 
              general, you can read this article and tune in to a podcast episode where Planorama Design’s 
              founder Matt Genovese dicusses why user stories are important, tips for writing them, and how 
              to avoid some common mistakes.
            </div>
          </div>
          <div className='py-4 link-card'>
            <span className='link'>
              <a
                href='https://planorama.design/blog/the-value-of-user-stories-when-building-your-saas-product/'
                target={'_blank'}
              >
                Learn more
              </a>
            </span>
          </div>
        </div>
      </Content>
    </BaseContent>
  )
}