import { IHistoryState } from "./types"

export function listHistoryRequest() {
  return {
    type: 'LIST_HISTORY_REQUEST',
  }
}

export function listHistorySuccess(history: IHistoryState) {
  return {
    type: 'LIST_HISTORY_SUCCESS',
    payload: {
      history,
    }
  }
}

export function listHistoryFailure() {
  return {
    type: 'LIST_HISTORY_FAILURE',
  }
}