import { ContainerHeader } from './styles'
import LogoUsg from '../../assets/usg-logo.svg'
import LogoUsgH from '../../assets/usg-logo-horizontal.svg'
import { ButtonOutlinedUsg } from '../../pages/steps/review/publicLinkLoggedOut/styles';

interface Props {
  type?: Number;
}

export const Header = ({ type = 1 }: Props) => {
  
  return (
    <>
      {type === 1 && (
      <ContainerHeader className="flex flex-column align-items-center justify-content-center">
        <div className="usg font-bold">
          <img src={LogoUsg} alt='Use Story Generator' />
        </div>
      </ContainerHeader>
      )}

      {type === 2 && (
        <ContainerHeader className='flex align-items-center justify-content-between'>
          <div className='usg-mini'>
            <img src={LogoUsgH} alt='Use Story Generator' />
          </div>
        </ContainerHeader>
      )}

      {type === 3 && (
        <ContainerHeader className='flex align-items-center justify-content-between'>
          <div className='usg-mini'>
            <img src={LogoUsgH} alt='Use Story Generator' />
          </div>
          <div
            className='
            xl:flex lg:flex md:flex sm:flex
            align-items-center justify-content-between
            hidden
            '
          >
            <strong>Try it yourself</strong>
            <div>
              <ButtonOutlinedUsg
                label='Start a new product idea'
                className='button-outlined-usg p-button-outlined mt-0 ml-4 font-light text-sm'
                style={{
                  border: '1px solid #45A582',
                  color: '#45A582',
                  height: '40px',
                }}
                onClick={() => (window.location.href = `/idea`)}
              />
            </div>
          </div>
        </ContainerHeader>
      )}
    </>
  )
}