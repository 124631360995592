import { Content } from './styles'

interface IProps {
  step: string;
}

export const MsgErrorAI = ({step}: IProps) => {
  return (
    <Content>
      <div className='
        flex flex-column
        align-items-start lg:align-items-center md:align-items-center sm:align-items-center
        text-start lg:text-center md:text-center sm:text-center
        my-6'
      >
        <i className="pi pi-exclamation-circle icon"></i>
        <div>
          <p className='my-2'>Unfortunately our AI could not figure out any good {step} to suggest.</p>
          <p>However, you can type in your own below.</p>
        </div>
      </div>
    </Content>
  )
}