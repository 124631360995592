import { Content } from './styles'
import { Button as Btn } from 'primereact/button'

interface InputProps {
  label: string
  loading?: boolean
  onClick?: any
  primary?: boolean
  disabled?: boolean
}

export const ButtonText = (props: InputProps) => {

  return (
    <Content>
      <Btn
        type='button'
        label={props.label}
        className={props.primary
          ? 'col-12 button-primary p-button-lg'
          : 'col-12 p-button-text button-usg p-button-lg'
        }
        iconPos="right"
        loading={props.loading}
        onClick={props.onClick}
        disabled={props.disabled}
      />
    </Content>
  )
}