import { 
  IProductIdeaState, IRemoveUserStory
} from "./types"

export function listProductIdeaRequest(productShortId: string) {
  return {
    type: 'LIST_PRODUCT_IDEA_REQUEST',
    payload: {
      productShortId,
    }
  }
}

export function listProductIdeaSuccess(productIdea: IProductIdeaState) {
  return {
    type: 'LIST_PRODUCT_IDEA_SUCCESS',
    payload: {
      productIdea,
    }
  }
}

export function listProductIdeaFailure(message: string) {
  return {
    type: 'LIST_PRODUCT_IDEA_FAILURE',
    payload: {
      message,
    }
  }
}

export function userStoryRemove({productId, featureId, groupId, storyId, shortId}: IRemoveUserStory) {
  return {
    type: 'USER_STORY_PRODUCT_IDEA_REMOVE',
    payload: {
      productId,
      featureId,
      groupId,
      storyId,
      shortId
    }
  }
}