import { Reducer } from "redux"
import { IHistoryState } from "./types"

const INITIAL_STATE: IHistoryState = {
  error: false, 
  loading: false, 
  histories: []
}

export const history: Reducer<IHistoryState> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'LIST_HISTORY_REQUEST': {
      return {...state, loading: true} 
    }
    case 'LIST_HISTORY_SUCCESS': {
      const { history } = action.payload
      return {...state, loading: false, error: false, histories: history} 
    }
    case 'LIST_HISTORY_FAILURE': {
      return {...state, loading: false, error: true} 
    }
    default: {
      return state
    }
  }
}