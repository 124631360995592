import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
})

interface IUserStoryProps{ 
  productId: string;
  featureId: string;
  role: string;
  idRole: string;
}

export const postUserStoryProps = async ({productId, featureId, role, idRole}: IUserStoryProps) => {
  let token = localStorage.getItem('token')
  if(token) token = JSON.parse(token)

  try {
    const { data } = await api.post(`/ai/product/${productId}/feature/${featureId}/group`, {role, idRole},
    {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    return data
  } catch (err) {
    return {error: err, msg: 'Tente novamente mais tarde!'}
  }
}