import { Sidebar } from 'primereact/sidebar'
import { Button } from '../Form/Button';
import { ButtonText } from '../Form/ButtonsText'
import { ContentSideBar } from './styles'

interface IProps {
  visibleBottom: boolean;
  onHide: any;
  handleLeave: any;
  loadingBtn: boolean;
}

export const SideBarLeave = (props: IProps) => {
  
  return (
    <ContentSideBar>
      <Sidebar
        visible={props.visibleBottom}
        position="bottom"
        onHide={props.onHide}
        modal={true}
        dismissable
        showCloseIcon={false}
        style={{height:'220px', borderTopRightRadius: '24px', borderTopLeftRadius: '24px'}}
        className='p-0'
      >
        <div className='flex flex-column align-items-center justify-content-center'>
          <div style={{maxWidth: '295px', fontSize: '16px'}} className='text-center'>
            <strong>Are you sure you want to leave? <br />
            No user stories were generated yet.</strong>
          </div>
          <div
            className='col sm:col lg:col-fixed md:col-fixed p-0 mt-2'
            style={{minWidth: '295px'}}
          >
            <Button
              label="Leave"
              loading={props.loadingBtn}
              onClick={props.handleLeave}
              type='button'
            />
          </div> 
          <div
            className='col sm:col lg:col-fixed md:col-fixed p-0 mt-3 sm:mt-0 md:mt-0 lg:mt-0'
            style={{minWidth: '295px'}}
          >
            <ButtonText
              label="Back"
              onClick={props.onHide}
            />
          </div> 
        </div>
      </Sidebar>
    </ContentSideBar>
  )
}