import styled from "styled-components"
import mobileMenu from '../../assets/menu.png'

export const ContainerNav = styled.div`
  @media (min-width: 600px) {
    width: 600px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }

  height: 1.7rem;

  .toggle {
    width: 24px;
    height: 24px;
    background: url(${mobileMenu}) no-repeat center center;
    border: 0px;
    margin-top: 8px;
    outline: none;
  }

  .toggle:hover {
    width: 24px;
    height: 24px;
    background: url(${mobileMenu}) no-repeat center center;
    border: 0px;
    margin-top: 8px;
    outline: none;
  }

  .button {
    width: 100%;
    background: none;
    border: 0;
    color: #000;
    font-size: 2rem;
  }

  .cardNav {
    opacity: 0;
    pointer-events: none;
  }

  .navMobileActive {
    transition: 0.3s;
    transform: initial;
    opacity: 1;
    pointer-events: initial;
    z-index: 100;
  }

  .button:hover {
    width: 100%;
    background: none;
    border: 0;
    color: var(--green);
  }

  .email {
    width: 100%;
    font-size: 10px;
    text-transform: uppercase;
  }

  .linkLogo {
    cursor: pointer;
  }
`

export const ContainerNavLg = styled.div`

  .toggle {
    width: 24px;
    height: 24px;
    background: url(${mobileMenu}) no-repeat center center;
    border: 0px;
    margin-top: 8px;
    outline:none ;
  }

  .toggle:hover {
    width: 24px;
    height: 24px;
    background: url(${mobileMenu}) no-repeat center center;
    border: 0px;
    margin-top: 8px;
    outline:none ;
  }

  .toggle:focus {
    outline:none ;
  }

  .button {
    width:100% ;
    background: none ;
    border: 0 ;
    color: #000 ;
    font-size: 2rem ;
  }

  .button:hover {
    width:100% ;
    background: none ;
    border: 0 ;
    color: var(--green) ;
  }

  .cardNav {
    opacity:0 ;
    pointer-events: none ;
  }

  .navMobileActive {
    transition: 0.3s ;
    transform: initial ;
    opacity: 1;
    pointer-events: initial ;
    z-index: 100;
  }

  .email {
    width: 100% ;
    font-size:10px ;
    text-transform: uppercase ;
  }

  .linkLogo {
    cursor: pointer;
  }
`