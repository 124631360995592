import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CardHistory } from '../../components/CardHistory'
import { Loading } from '../../components/Loading' 
import { Nav } from '../../components/Nav'
import { IState } from '../../store'
import { listHistoryRequest } from '../../store/modules/history/actions'
import { IHistory, IHistoryState } from '../../store/modules/history/types'
import {
  ReviewContent,
  ButtonUsg,
  BaseContent,
  Content
} from './styles'

export const History = () => {
  const dispatch = useDispatch()
  const listHistory = useSelector<IState, IHistoryState>(state => state.history)

  useEffect(() => {
    dispatch(listHistoryRequest())
  }, [])

  return (
    <BaseContent>
      {listHistory.loading && ( 
        <Loading /> 
      )} 
      <Content className='flex flex-column align-items-center justify-content-center px-5 m-0'>     
        <div className="m-0 col-12 p-0">
          <Nav logo type={2}/>
        </div>  
        <ReviewContent className="flex flex-column align-items-center justify-content-center">
          <div className='title col-12 p-0'>
            History
          </div>
          <div className='review flex flex-wrap'>
            {listHistory.histories.map((history: IHistory, i) => (
              <div key={i} className='col-12 sm:col-12 lg:col-4 md:col-6 px-0 py-1 lg:p-2 md:p-2'>
                <CardHistory 
                  idea={history.idea}
                  userStoriesCount={history.userStoriesCount}
                  createdAt={history.createdAt}
                  handler={() => window.location.href = `/${history.shortId}`}
                />
              </div>
            ))}
          </div>
          <div className='col-12 p-0 text-center'>
            <div hidden>
              <ButtonUsg
                label="Generate more user stories"
                className='button-usg'
              />
            </div>
            <div hidden>
            <ButtonUsg 
              label="Start a new product idea"
              className='button-outlined-usg p-button-outlined'
              style={{
                border: '1px solid #45A582', 
                color: '#45A582'
              }}
            />
            </div>
            <div>
              <ButtonUsg 
                label="Start a new product idea"
                className='button-outlined-usg p-button-outlined px-0 m-0 my-5'
                style={{color: '#45A582'}}
                onClick={() => window.location.href = `/idea`}
              />
            </div>
          </div>
        </ReviewContent>
      </Content>
    </BaseContent>
  )
}