import styled from "styled-components"

export const BaseContent = styled.div`
display: flex ;
justify-content: center ;
padding: 1.5rem ;
padding-top:0;
`

export const Content = styled.div`
@media (min-width: 600px) {
  width: 600px;
}

@media (max-width: 599px) {
  width: 100%;
}

.title {
  font-size: 2rem;
  font-weight: 500;
}

.card {
  border-radius: 16px;
  box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.1);
}

.link-card {
  background-color: var(--green) ;
  border-end-end-radius: 16px;
  border-end-start-radius:16px ;
  text-align: center ;

  & .link {
    cursor: pointer;
  }
}

a {
  text-decoration: none ;
  color: #FFF ;
}

a:hover {
  text-decoration: underline;
}
`