import { Content } from './styles'
import { RadioButton as Radio } from 'primereact/radiobutton'
import { useField } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

export const RadioButtonText = (props: any) => {  
  const [inputProps, meta] = useField(props)

  return (
    <Content>
      <div className="field-radiobutton -mb-1 gap-2">
        <Radio 
          {...inputProps}
          key={'radio'}
          name={props.name}
          value={'customized'}
          style={{marginTop: '15px'}}
          checked={props.valuesInput === 'customized'}
        />
        <div className='base-input col p-0 pl-3'>
          <InputText
            name='text'
            className='col input-text p-0'
            value={props.valueText}
            onChange={(e) => props.setNewFeature(e.target.value)}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
          />
          <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-text text"
            type='button'
            onClick={() => props.handler()}
            disabled={props.valueText.length === 0 ? true : false}
          />
        </div>
      </div>
    </Content>
  )
}