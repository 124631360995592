import { StepsContent } from './styles'

interface stepsList {
  title: string,
  description: string
}

interface Props {
  stepsList: stepsList[]
}

export const Steps = ({ stepsList }: Props) => {
  return (
    <StepsContent className="flex flex-wrap align-items-start justify-content-start col-12 p-0">
      {stepsList.map(step => (
        <div key={step.title} className='sm:col-12 lg:col-4 col-12 mb-4'>
          <div className='step-title'>
            {step.title}
          </div>
          <div className='step-subtitle'>
            {step.description}
          </div>
      </div>
      ))}
    </StepsContent>
    
  )
}