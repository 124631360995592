import { Sidebar } from 'primereact/sidebar'
import { ContentSideBar } from './styles'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useState } from 'react';
import { Button } from '../Form/Button';

interface IProps {
  visibleBottom: boolean;
  onHide: any;
  loadingBtn: boolean;
  shortId: string;
}

const domain = process.env.REACT_APP_DOMAIN
const shortDomain = process.env.REACT_APP_DOMAIN?.replace(/https?:\/\//, '')

export const SideBarRecommend = (props: IProps) => {
  const [copy, setCopy] = useState(false)

  const onHide = () =>{
    setCopy(false)
    props.onHide()
  }
  
  return (
    <ContentSideBar>
      <Sidebar
        visible={props.visibleBottom}
        position="bottom"
        onHide={onHide}
        modal={true}
        dismissable
        showCloseIcon={false}
        style={{
          height:'220px',
          borderTopRightRadius: '24px',
          borderTopLeftRadius: '24px'
        }}
        className='p-0'
      >
        <div className='flex flex-column align-items-center justify-content-center'>
          <div style={{maxWidth: '295px', fontSize: '16px'}} className='text-center'>
            <strong>Copy, share, and recommend!</strong>
          </div>
          <div className='mt-3'>
            <CopyToClipboard text={`${domain}/${props.shortId}`}>
              <div
                className='col sm:col lg:col-fixed md:col-fixed p-0'
                style={{minWidth: '295px'}}
              >
                <Button
                  label={copy ? 'Copied to clipboard!' : 'Copy link'}
                  type='button'
                  onClick={() => setCopy(true)}
                  disabled={copy}
                />
              </div>
            </CopyToClipboard>
          </div> 
          <div className='mt-3'>
            {shortDomain}/{props.shortId}
          </div> 
        </div>
      </Sidebar>
    </ContentSideBar>
  )
}