import { useParams } from 'react-router-dom';
import { ReviewContent } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { IState } from '../../../../store';
import { IRemoveUserStory, IReviewState } from '../../../../store/modules/review/types';
import { useEffect, useState } from 'react';
import { listReviewRequest, userStoryRemove } from '../../../../store/modules/review/actions';
import { Steps } from '../../../../components/Steps';
import { CardReview } from '../../../../components/CardReview';
import { ButtonText } from '../../../../components/Form/ButtonsText';
import { ContentTop } from '../../../../components/ContentTop';
import { SideBarLeave } from '../../../../components/SideBarLeave';
import { Button } from '../../../../components/Form/Button';
import { Loading } from '../../../../components/Loading'; 
import { postUserStoryProps } from '../../../../services/role';
import { GenericError } from '../../../genericError';
import { TypeErrors } from '../../../../enums/TypeErrors';

export const Default = () => {
  const { productShortId, shortId } = useParams();
  const dispatch = useDispatch()
  const review = useSelector<IState, IReviewState>(state => state.review) 

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loadingReGenerate, setLoadingReGenerate] = useState(false)
  const [featureSelected, setFeatureSelected] = useState('')
  const [roleSelected, setRoleSelected] = useState('')
  const [visibleBottom, setVisibleBottom] = useState(false)
  const [error, setError] = useState(false)
  const [short, setShort] = useState('')

  const ideaLs = localStorage.getItem('idea') || ''

  const stepsList = [
    {
      title: '1. IDEA',
      description: review.idea || ideaLs
    },
    {
      title: '2. FEATURE',
      description: featureSelected
    },
    {
      title: '3. ROLE',
      description: roleSelected
    }
  ]

  const BtnStart = ({classStart}: any) => {
    return (
      <div
        className={classStart}
        style={{minWidth: '250px'}}
      >
        <Button
          label="Start a new product idea"
          loading={loadingBtn}
          onClick={handleStartNewIdea}
          type={'button'}
          style='p-button-outlined'
        />
      </div>
    )
  }

  const WellDone = () => {
    return (
      <div className='titleForm col-12 p-0 mt-5'>
        Well done! Here are some user stories our AI has generated for you.
      </div>
    )
  }

  const MsgError = () => {
    return (
      <div 
        className='
        flex flex-column
        align-items-start lg:align-items-center md:align-items-center sm:align-items-center
        text-start lg:text-center md:text-center sm:text-center
        mt-6'
        style={{color: 'var(--green)'}}
      >
        <i className={`pi pi-exclamation-circle icon ${review.loading ? 'hidden' : ''}`} style={{fontSize: '1.5rem'}}></i>
        <div>
          {review.typeError === TypeErrors.MAX_RETRIES_EXCEEDED ? (
            <>
              <p className={`mt-2 mb-4 ${review.loading ? 'hidden' : ''}`}>
                Unfortunately our AI had a problem creating any user story suggestions with your selections.
              </p>
              <p className={`${review.loading ? 'hidden' : ''}`}>
                Please try selecting a different feature or role, or tweaking the text for the feature you selected.
              </p>            
            </>
          ) : (
            <>
              <p className={`mt-2 mb-4 ${review.loading ? 'hidden' : ''}`}>
                Unfortunately our AI could not come up with any user stories.
              </p>
              <p className={`${review.loading ? 'hidden' : ''}`} style={{color: '#000'}}>
                Make sure to add a reasonable yet small amount of information to make it easier.
              </p>
            </>
          )}
        </div>
      </div>      
    )
  }

  const Exception = () => {
    return (
      <>
      {review.allRecordsDeleted ? (
        <>
        <WellDone />
        <div 
          className='
          flex flex-column
          align-items-start lg:align-items-center md:align-items-center sm:align-items-center
          text-start lg:text-center md:text-center sm:text-center
          mt-6 p-0 col-12'
          style={{color: 'var(--green)'}}
        >
          <i className={`pi pi-exclamation-circle icon ${review.loading ? 'hidden' : ''}`} style={{fontSize: '1.5rem'}}></i>
          <div>
            <p className={`mt-2 ${review.loading ? 'hidden' : ''}`}>You have dismissed all user stories.</p>
          </div>
        </div>
        </>
      ) : (                
        <MsgError />
      )}
      </>
    )
  }

  useEffect(() => {
    if(shortId) dispatch(listReviewRequest(shortId))

    const featureLs = localStorage.getItem('feature') || ''
    setFeatureSelected(featureLs)

    const RoleLs = localStorage.getItem('role') || ''
    setRoleSelected(RoleLs)
  }, [])

  useEffect(() => {
    setShort(shortId || '')
  }, [shortId])

  const handleSave = () => {    
    localStorage.removeItem('idRole')
    localStorage.removeItem('role')
    localStorage.removeItem('feature')
    localStorage.removeItem('ideaId')
    localStorage.removeItem('idSelectedOption')
    localStorage.removeItem('idea')
    window.location.href = `/review/public-link/${productShortId}`
  }

  const handleLeave = () => {
    window.location.href = `/`;
  }

  const handlerDismiss = (storyId: string) => {
    const payload: IRemoveUserStory = {
      productId: review.productId,
      featureId: review.featureId,
      groupId: review.userStoryGroupId,
      storyId,
      shortId: short
    }

    dispatch(userStoryRemove(payload))
  }

  const handleTryAnother = () => {
    const productId = review.productId.length > 0 ? review.productId : localStorage.getItem('ideaId')
    window.location.href = `/feature/${productId}`
  }

  const handleStartNewIdea = () => {
    const ideaLs = localStorage.getItem('idea')
    if(ideaLs) localStorage.removeItem('idea')

    window.location.href = `/idea`
  }

  const handleReGenerate = async () => {
    setLoadingReGenerate(true)

    const idRoleLs = localStorage.getItem('idRole') || ''
    const roleLs = localStorage.getItem('role') || ''

    const body = {
      productId: review.productId,
      featureId: review.featureId,
      role: roleLs,
      idRole: JSON.parse(idRoleLs)
    }

    const result = await postUserStoryProps(body)
    if(result.error) {     
      setError(true)
      setLoadingReGenerate(false)
      return
    }
    setShort(result.shortId)
    setLoadingReGenerate(false)    
    dispatch(listReviewRequest(result.shortId))
  }
  
  return (
    <div>
      {review.loading && ( 
        <Loading /> 
      )}
      {error ? (
        <GenericError />
      ) : (
        <div className='flex flex-column align-items-center justify-content-center px-5 py-2 m-0'>        
          <ReviewContent className="flex flex-column align-items-center justify-content-center">
            <ContentTop
              handleClick={() => setVisibleBottom(true)}
            />
            <Steps stepsList={stepsList}/>
            <div className='title col-12 p-0'>
              4. Review stories
            </div>
            {review.userStories.length > 0 ? (
              <>
                <WellDone />
                <div className='review flex flex-wrap col-12 p-0 justify-content-start'>
                  {review.userStories.map(us => (
                    <CardReview
                      key={us._id}
                      review={us.capability}
                      handler={() => handlerDismiss(us._id)}
                    />
                  ))}
                </div>
              </>
            ) : (
              <Exception />
            )}          
            <div className={`
              flex flex-wrap flex-row-reverse justify-content-center 
              mt-5 lg:mt-7 md:mt-7 sm:mt-5 col-12 p-0`
              }
            >
              {review.userStories.length > 0 ? (
                <div
                  className='col sm:col lg:col-fixed md:col-fixed p-0'
                  style={{minWidth: '295px'}}
                >
                  <Button
                    label="Next"
                    loading={loadingBtn}
                    onClick={handleSave}
                    type={'button'}
                  />
                </div>
              ) : (   
                <>
                  {review.allRecordsDeleted ? (
                    <div className='flex flex-wrap gap-3 col-12 p-0'>
                      <div
                        className='col-12 lg:col md:col sm:col-12 p-0'
                      >
                        <Button
                          label="Re-generate stories"
                          loading={loadingReGenerate}
                          onClick={handleReGenerate}
                          type={'button'}
                        />
                      </div>
                      <div
                        className='col-12 lg:col md:col sm:col-12 p-0'
                      >
                        <Button
                          label="Try another feature"
                          loading={loadingBtn}
                          onClick={handleTryAnother}
                          type={'button'}
                          style='p-button-outlined'
                        />
                      </div>
                      <BtnStart classStart='col-12 lg:col md:col sm:col-12 p-0' />
                    </div>
                  ) : (
                    <div className='flex flex-wrap gap-3 col-12 p-0'>
                    {review.error && (
                      <>
                      <div
                        className='col p-0'
                        style={{minWidth: '250px'}}
                      >
                        <Button
                          label={review.typeError === TypeErrors.MAX_RETRIES_EXCEEDED 
                            ? 'Re-select feature'
                            : 'Try another feature'
                          }
                          loading={loadingBtn}
                          onClick={handleTryAnother}
                          type={'button'}
                        />
                      </div>
                      <BtnStart classStart='col p-0' />
                      </>
                    )}
                    </div>
                  )}    
                </>           
              )}
              <div
                className='col sm:col lg:col-fixed md:col-fixed p-0 mt-3 sm:mt-0 md:mt-0 lg:mt-0'
                style={{minWidth: '295px'}}
              >
                <ButtonText
                  label="Back"
                  onClick={() => window.location.href = `/role/${review.productId}/${review.featureId}`}
                />
              </div>  
            </div>
          </ReviewContent>
        </div>
      )}
      <SideBarLeave
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        handleLeave={handleLeave}
        loadingBtn={loadingBtn}
       />
    </div>
  )
}