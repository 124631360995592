import styled from "styled-components"

export const Card = styled.div`
  @media (max-width: 575px) {
    margin-bottom: 0.6rem ;
  }

  @media (min-width: 768px) {
    height: 130px;
  }

  cursor: pointer;
  padding: 1rem;
  background-color: #F4F4F4;
  border-radius: 4px;
  font-size: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`