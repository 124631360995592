import { Content } from './styles'
import { InputTextarea } from 'primereact/inputtextarea';
import { useField } from 'formik'

export const TextArea = (props: any) => {
  const [inputProps, meta] = useField(props)

  return (
    <Content>
      <div className='col-12 p-0 relative'>
        <InputTextarea
          placeholder={props.placeholder}
          {...inputProps}
          {...props}
          className={meta.error ? 'input col-12 p-invalid block': 'input col-12'}
          autoResize 
        />
        <div className='col-12 p-0 character'>
          <span className={meta.error ? 'text-red-500': ''}>{inputProps.value.length}</span>/255
        </div>
      </div>
      {meta.error && (
        <div className='error p-0'>{meta.error.toString()}</div>
      )}
    </Content>
  )
}