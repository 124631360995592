import { IRoleState } from "./types"

interface IRoleProps {
  productId: string;
  featureId: string;
  groupId: string;
}

export function listRoleRequest(productId: string, featureId: string, groupId: string) {
  return {
    type: 'LIST_ROLE_REQUEST',
    payload: {
      productId,
      featureId,
      groupId
    }
  }
}

export function listRoleSuccess(role: IRoleState) {
  return {
    type: 'LIST_ROLE_SUCCESS',
    payload: {
      role,
    }
  }
}

export function listRoleFailure({productId, featureId, groupId}: IRoleProps) {
  return {
    type: 'LIST_ROLE_FAILURE',
    payload: {
      productId,
      featureId,
      groupId
    }
  }
}