import * as Yup from 'yup'

export default Yup.object().shape({
  role: Yup.string().required('Required.'),

  roleText: Yup.string().when('role', {
    is: 'customized',
    then: Yup.string().required('Required.')
  })
})
