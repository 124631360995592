import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --green: #45A582;
    --type-first:'Roboto', sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: var(--type-first);
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  img {
    display: block;
    max-width: 100%;
  }

  @keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #45A582;
    }
    40% {
        stroke: #45A582;
    }
    66% {
        stroke: #45A582;
    }
    80%,
    90% {
        stroke: #45A582;
    }
  }
`