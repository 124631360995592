import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { IProductState } from './modules/product/types'
import { IReviewState } from './modules/review/types'
import { IRoleState } from './modules/role/types'
import { IHistoryState } from './modules/history/types'
import rootSaga from './modules/rooSaga'
import rootReducers from './modules/rootReducers'
import { IProductIdeaState } from './modules/productIdea/types'

export interface IState {
  product: IProductState;
  role: IRoleState;
  review: IReviewState;
  history: IHistoryState;
  productIdea: IProductIdeaState;
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const store = createStore(
  rootReducers,
  composeWithDevTools(
    applyMiddleware(...middlewares)
  )
)

sagaMiddleware.run(rootSaga)

export default store