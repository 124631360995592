import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../../services/product'
import { 
  listProductIdeaRequest,
  listProductIdeaSuccess,
  listProductIdeaFailure,
  userStoryRemove
} from './actions'
import { IProductIdeaState } from './types'

type GetProductIdeaRequest = ReturnType<typeof listProductIdeaRequest>
type DeleteUserStoryRemove = ReturnType<typeof userStoryRemove>

let token = localStorage.getItem('token')
token = token ? JSON.parse(token) : ''

function* getProductIdea({ payload }: GetProductIdeaRequest) {
  try {
    const productIdeaList: AxiosResponse<IProductIdeaState> = yield call(
      api.get, 
      `/p/${payload.productShortId}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    )
  
    if(productIdeaList.data){
      yield put(listProductIdeaSuccess(productIdeaList.data))
    }
  } catch (e: any) {
    yield put(listProductIdeaFailure(e.message))
  }
}

function* deleteUserStory({ payload }: DeleteUserStoryRemove) {
  const { productId, featureId, groupId, storyId, shortId } = payload

  const result: AxiosResponse = yield call(
    api.delete,
    `/ai/product/${productId}/feature/${featureId}/group/${groupId}/userstory/${storyId}`,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )

  yield put(listProductIdeaRequest(shortId))
}

export default all([
    takeLatest('LIST_PRODUCT_IDEA_REQUEST', getProductIdea),
    takeLatest('USER_STORY_PRODUCT_IDEA_REMOVE', deleteUserStory)
])