import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CardReview } from '../../../../components/CardReview'
import { Button } from '../../../../components/Form/Button'
import { ButtonSecundary } from '../../../../components/Form/ButtonSecundary'
import { Loading } from '../../../../components/Loading' 
import { Nav } from '../../../../components/Nav'
import { SideBarRecommend } from '../../../../components/SideBarRecommend'
import { IState } from '../../../../store'
import { listProductIdeaRequest, userStoryRemove } from '../../../../store/modules/productIdea/actions'
import { IRemoveUserStory, IUsgs } from '../../../../store/modules/productIdea/types'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  ReviewContent,
  BaseContent,
  Content
} from './styles'
import { IProductIdeaState } from '../../../../store/modules/productIdea/types'

export const PublicLink = () => {
  const { productShortId } = useParams();
  const dispatch = useDispatch()
  const productIdea = useSelector<IState, IProductIdeaState>(state => state.productIdea) 

  const [visibleBottom, setVisibleBottom] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [existUserStories, setExistUserStories] = useState(false)

  const Exception = () => {
    return (
      <div 
        className='
        flex flex-column
        align-items-start lg:align-items-center md:align-items-center sm:align-items-center
        text-start lg:text-center md:text-center sm:text-center
        mt-6 p-0 col-12'
      >
        <i className={`pi pi-exclamation-circle icon ${productIdea.loading ? 'hidden' : ''}`} style={{fontSize: '1.5rem'}}></i>
        <div>
          <p className={`mt-2 ${productIdea.loading ? 'hidden' : ''}`}>You do not have user stories saved yet.</p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if(productShortId) dispatch(listProductIdeaRequest(productShortId))
  }, [])

  useEffect(() => {
    setExistUserStories(false)
    for(let t of productIdea.usgs) {
      for(let f of t.userStories) {
        if(f.isDeleted === false) setExistUserStories(true)
      }
    }
  }, [productIdea])

  const handleGenerate = () => {
    setLoadingBtn(true)
    window.location.href = `/feature/${productIdea.productId}`;
  }

  const handlerDismiss = (featureId: string, groupId: string, storyId:string) => {
    const payload: IRemoveUserStory = {
      productId: productIdea.productId,
      featureId,
      groupId,
      storyId,
      shortId: productShortId || ''
    }
    dispatch(userStoryRemove(payload))
  }

  return (
    <HelmetProvider>
    <BaseContent>    
        <Helmet>
          <title>User Story Generator: AI helps you write user stories | Planorama Labs</title>
          <meta name="description" content={productIdea.idea} />
          <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/review/public-link/${productShortId}`} />

          <meta property="og:description" content={productIdea.idea} />
          <meta property="og:title" content="User Story Generator: AI helps you write user stories | Planorama Design" />

          <meta name="twitter:description" content={productIdea.idea} />
          <meta name="twitter:title" content="User Story Generator: AI helps you write user stories | Planorama Design" />
        </Helmet>
      {productIdea.loading && ( 
        <Loading /> 
      )} 
      <Content className='flex flex-column align-items-center justify-content-center px-5 m-0'>     
        <div className="m-0 col-12 p-0">
          <Nav logo type={2}/>
        </div>  
        <ReviewContent className="flex flex-column align-items-center justify-content-center">
          <div className='txt col-12 p-0'>
            PRODUCT IDEA
          </div>
          <div className='title col-12 p-0'>
            {productIdea.idea}
          </div>
          <div className='txt mt-6 col-12 p-0'>
            USER STORIES
          </div>
          <div className='review flex flex-wrap col-12 p-0 justify-content-start'>
            {productIdea.usgs.length > 0 ? (
              <>
              {existUserStories ? (
                <>
                {productIdea.usgs.map(itemUsgs => (
                  itemUsgs.userStories.filter(f => f.isDeleted != true).map(us => (
                    <CardReview 
                      key={us._id}
                      review={us.capability}
                      handler={() => handlerDismiss(itemUsgs.featureId, itemUsgs.userStoryGroupId, us._id)}
                    />
                  ))
                ))}
                </>
              ) : (
                <Exception />
              )}              
              </>
            ) : (
              <Exception />
            )}
          </div>
          <div className='grid w-full mt-7'>
            <div className='col-12 lg:col md:col-12 sm:col-12'>
              <Button 
                label='Generate more user stories'
                loading={loadingBtn}
                type={'button'}
                onClick={handleGenerate}
              />
            </div>
            <div className='col-12 lg:col md:col-12 sm:col-12'>
              <ButtonSecundary
                label="Start a new product idea"
                type='button'
                onClick={() => window.location.href = "/idea"}
              />
            </div>
            <div className='col-12 lg:col md:col-12 sm:col-12'>
              <ButtonSecundary
                label="Share these results"
                type='button'
                onClick={() => setVisibleBottom(true)}
              />
            </div>
          </div>
        </ReviewContent>
      </Content>
      <SideBarRecommend
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        loadingBtn={loadingBtn}
        shortId={productShortId || ''}
      />
    </BaseContent>
    </HelmetProvider>
  )
}