import styled from "styled-components"
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

export const BaseContent = styled.div`
display: flex ;
justify-content: center ;
`

export const Content = styled.div`
@media (min-width: 600px) {
  width: 600px;
}

@media (max-width: 599px) {
  width: 100%;
}
`

export const OtpContent = styled.div`
width: 100%;

.txt {
  font-size: 2rem;
  font-weight: 500;
}

.titleForm {
  font-weight: 500;
  font-size: 1.25rem;
}
`