import styled from "styled-components"
import { Divider } from 'primereact/divider'

export const ContainerFooter = styled.div`
  width: 100%;

  .by-design {
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }

  .title {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
`

export const DividerUsg = styled(Divider)`
  margin-top: 80px;
  margin-bottom: 64px;
`