import { IdeaContent, ButtonUsg } from './styles'
import { ScrollPanel } from 'primereact/scrollpanel'
import { CardSample } from '../../../components/CardSample'
import { TextArea } from '../../../components/Form/TextArea'
import { Formik, Form } from 'formik'
import schema from './schema'
import { Button } from '../../../components/Form/Button'
import { useState } from 'react'
import { postAiProduct } from '../../../services/auth'
import { ContentTop } from '../../../components/ContentTop'
import { SideBarLeave } from '../../../components/SideBarLeave'
import { GenericError } from '../../genericError'

interface IIdeas {
  id: number;
  sample: string;
}

const ideas: IIdeas[] = [
  {
    id: 1,
    sample: 'Gas station owners can monitor sales and inventory of their store products and gasoline.'
  }, 
  {
    id: 2,
    sample: 'Professors manage students’ online homework by grading assignments and providing video tutoring.'
  }, 
  {
    id: 3,
    sample: 'Assist adults with automated reminders to take medicine at the right time each day.'
  }, 
  {
    id: 4,
    sample: 'Manage entry of oil refinery data for benchmarking performance metrics across similar refineries.'
  }, 
  {
    id: 5,
    sample: 'Pet owners can find the perfect dog walker by viewing profiles, reading reviews, and booking services.'
  }, 
  {
    id: 6,
    sample: 'Personal trainers assist their students by tracking exercise workout metrics to achieve personal goals.'
  }, 
]

interface IIdeaForm {
  productDescription: string;
}

export const Idea = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [visibleBottom, setVisibleBottom] = useState(false)
  const [error, setError] = useState(false)
  
  const initialValues: IIdeaForm = {
    productDescription: localStorage.getItem('idea') || ''
  }

  const handleSubmit = async (values: IIdeaForm) => {
    setLoadingBtn(true)

    const result = await postAiProduct(values)

    if(result.error) {      
      setError(true)
      setLoadingBtn(false)
      return
    }

    setLoadingBtn(false)
    localStorage.setItem('idea', values.productDescription)
    localStorage.setItem('ideaId', result._id)
    window.location.href = `/feature/${result._id}`;
  }

  const handleCard = (sample: string, setFieldValue: any) => {
    setFieldValue('productDescription', sample)
  }

  const handleLeave = () => {
    window.location.href = `/`;
  }

  return (
    <div>
      {error ? (
        <GenericError />
      ) : (
        <div className='flex flex-column align-items-center justify-content-center p-4 m-0'>        
          <IdeaContent className="flex flex-column align-items-center justify-content-center">
            <ContentTop contentTitle='1. Idea' handleClick={() => setVisibleBottom(true)}/>
            <div className='titleForm mt-5'>
              Write a product idea in a few sentences or use one of the samples below.
            </div>
            <Formik
              validationSchema={schema}
              initialValues= {initialValues}
              onSubmit={handleSubmit}       
            >
              {({ values, errors, setFieldValue }) => (
                <Form>    
                  <div>
                    <TextArea
                      name='productDescription'
                      rows={5} 
                      cols={30}
                      placeholder='E.g. A mobile app to remind me to take my medicine in the right time everyday.'
                    />
                  </div>
                  <div className='txt mt-5 mb-4'>
                    SAMPLES
                  </div>
                  <div>
                    <ScrollPanel className="custom-scroll">
                      <div className='flex flex-wrap'>
                        {ideas.map(idea => (
                          <CardSample
                            key={idea.id}
                            sample={idea.sample}
                            handler={() => handleCard(idea.sample, setFieldValue)}
                          />
                        ))}
                      </div>
                    </ScrollPanel>
                  </div>
                  <div className='flex justify-content-center col p-0'>
                    <div
                      className='col lg:col-4 md:col-4 mt-5 lg:mt-7 md:mt-7 sm:mt-5'
                    >
                      <Button
                        label="Start"
                        loading={loadingBtn}
                      />
                    </div>
                  </div>    
                </Form>
              )}
            </Formik>  
          </IdeaContent>
        </div>
      )}
      <SideBarLeave 
        visibleBottom={visibleBottom}
        onHide={() => setVisibleBottom(false)}
        handleLeave={handleLeave}
        loadingBtn={loadingBtn}
       />
    </div>
  )
}