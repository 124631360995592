import styled from "styled-components"

export const BaseContent = styled.div`
display: flex ;
justify-content: center ;
`

export const Content = styled.div`
@media (min-width: 600px) {
  width: 600px;
}

@media (max-width: 599px) {
  width: 100%;
}
`

export const DefaultContent = styled.div`
  width: 100%;

  .txt {
    font-size: 16px;
    margin: 59px 0px 56px 0px;
  }

  .titleForm {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .info {
    font-size: 12px;
  }

  .subTitle {
    font-size: 16px;
  }

  a:link, a:visited {
    color: var(--green);
    text-decoration: none ;
  }

  .link-example {
    color: var(--green);
    display: flexbox;
    cursor: pointer;
  }

  .link-example:hover {
    text-decoration: underline ;
  }
`