import styled from "styled-components"

export const Contenttop = styled.div`
  @media (min-width: 900px) {
    width: 850px;
  }

  @media (max-width: 899px) {
    width: 100%;
  }

  .title {
    font-weight: 500;
    font-size: 2rem;
  }
`