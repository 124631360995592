import { combineReducers } from 'redux'
import { product } from './product/reducer'
import { productIdea } from './productIdea/reducer'
import { role } from './role/reducer'
import { review } from './review/reducer'
import { history } from './history/reducer'

export default combineReducers({
  product,
  role,
  review,
  history,
  productIdea
})