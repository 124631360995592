import { Nav } from '../../components/Nav'
import {
  HomeContent,
  BaseContent,
  Content
} from './styles'
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { useMedia } from '../../Hooks/useMedia';
import { Button } from '../../components/Form/Button';
import { ButtonText } from '../../components/Form/ButtonsText';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
  console.log('Version 0.1.0')
  const mobile = useMedia('(max-width: 40rem)')
  const [loadingBtn, setLoadingBtn] = useState(false)

  const handlerStart = () => {
    if(localStorage.getItem('idea')) localStorage.removeItem('idea')
    window.location.href = "/idea"
  }

  return (     
    <>
    <Helmet>
      <title>User Story Generator: AI helps you write user stories | Planorama Labs</title>
      <meta name="description" content={'Agile teams capture requirements as user stories. Using AI, our free app helps you get past writer’s block and unlock new feature ideas for your product as user stories narratives.'} />
      <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}/`} />

      <meta property="og:description" content={'Agile teams capture requirements as user stories. Using AI, our free app helps you get past writer’s block and unlock new feature ideas for your product as user stories narratives.'} />
      <meta property="og:title" content="User Story Generator: AI helps you write user stories | Planorama Design" />
      
      <meta name="twitter:description" content={'Agile teams capture requirements as user stories. Using AI, our free app helps you get past writer’s block and unlock new feature ideas for your product as user stories narratives.'} />
      <meta name="twitter:title" content="User Story Generator: AI helps you write user stories | Planorama Design" />
    </Helmet>
    <Nav logo={false}/>
    <BaseContent>
      <Content className='flex flex-column align-items-center justify-content-center p-4 m-0'>
        <Header />
        <HomeContent className="flex flex-column align-items-center justify-content-center">        
          <div className='base'>
            <div className='mt-7'>
              <Button
                label="+ Start new product idea"
                loading={loadingBtn}
                full
                onClick={handlerStart}
                type={'button'}
              />
            </div>          
            <div className='mt-3'>
              <ButtonText
                label="View your history" 
                onClick={() => window.location.href = `/history`}
              />
            </div>
          </div>       
        </HomeContent>
        <Footer />
      </Content>
    </BaseContent>
    </>
  );
};