import styled from "styled-components"
import { Button } from 'primereact/button'

export const BaseContent = styled.div`
display: flex ;
justify-content: center ;
`

export const Content = styled.div`
@media (min-width: 900px) {
  width: 850px;
}

@media (max-width: 899px) {
  width: 100%;
}
`

export const ReviewContent = styled.div`
width: 100%;

.txt {
  color: var(--green);
  font-weight: 700;
  font-size: 0.9rem;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 1rem;
}

.titleBtn {
  font-weight: 500;
  font-size: 2rem;
}

.review {
  margin-top: 1rem;
}
`

export const ButtonUsg = styled(Button)`
  margin-top: 1rem;
  background-color: var(--green);
  border: 0;
  color: #fff !important;
  font-size: 1rem !important ;

  &.button-usg:hover {
    background-color: #255845 !important;
    border: 0;
  }
  
  .button-outlined-usg .p-button{
    border: '1px solid #45A582'
  }
`

export const ButtonOutlinedUsg = styled(Button)`
  margin-top: 1rem;
  background-color: white;
  border: '1px solid #45A582';
  color: var(--green)!important;
  font-size: 1rem !important ;
`