import { all } from 'redux-saga/effects'

import product from './product/sagas'
import productIdea from './productIdea/sagas'
import role from './role/sagas'
import review from './review/sagas'
import history from './history/sagas'

export default function* rootSaga(): any {
  return yield all([
    product,
    role,
    review,
    history,
    productIdea
  ])
}