import { useMedia } from '../../Hooks/useMedia'
import { ContainerNav, ContainerNavLg } from './styles'
import LogoUsgH from '../../assets/usg-logo-horizontal.svg'
import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import { Sidebar } from 'primereact/sidebar'

interface IProps {
  logo?: boolean;
  type?: number;
}

export const Nav = ({logo, type = 1}: IProps) => {
  const mobile = useMedia('(max-width: 40rem)')

  const [email, setEmail] = useState('')
  const [mobileMenu, setMobileMenu] = useState(false)

  useEffect(() => {
    let loginOrCreate: any = localStorage.getItem('LoginOrCreate')

    if(loginOrCreate) {
      loginOrCreate = JSON.parse(loginOrCreate)
      setEmail(loginOrCreate.ChallengeParameters.email)
    }
  }, [])

  const handleHome = () => {
    setMobileMenu(false)
    window.location.href = `/`;
  }

  const handleHistory = () => {
    setMobileMenu(false)
    window.location.href = `/history`;
  }

  const handleAbout = () => {
    setMobileMenu(false)
    window.location.href = `/about`;
  }

  const handleLogOut = () => {
    setMobileMenu(false)
    localStorage.removeItem('token')
    localStorage.removeItem('LoginOrCreate')
    window.location.href = `/auth`;

  }

  return (
    <>
      {type === 1 ? (
        <div className='flex align-items-center justify-content-center px-4 pt-4 m-0'>
          <ContainerNav className='flex flex-column'>
            <div className='flex'>
              <Button
                aria-label='Menu'
                onClick={() => setMobileMenu(!mobileMenu)}
                className='toggle outline-none'
              />
              <div className='ml-4'>
                {!mobile && logo && (
                  <img
                    className='linkLogo'
                    src={LogoUsgH}
                    alt='Use Story Generator'
                    onClick={() => (window.location.href = `/`)}
                  />
                )}
              </div>
            </div>
          </ContainerNav>
        </div>
      ) : (
        <div className='flex align-items-center justify-content-start pt-4 m-0 col-12 p-0'>
          <ContainerNavLg className='flex flex-column mb-7'>
            <div className='flex'>
              <Button
                aria-label='Menu'
                onClick={() => setMobileMenu(!mobileMenu)}
                className='toggle'
              />
              <div className='ml-4'>
                {!mobile && logo && (
                  <img
                    className='linkLogo'
                    src={LogoUsgH}
                    alt='Use Story Generator'
                    onClick={() => (window.location.href = `/`)}
                  />
                )}
              </div>
            </div>
          </ContainerNavLg>
        </div>
      )}
      <Sidebar
        visible={mobileMenu}
        fullScreen
        onHide={() => setMobileMenu(false)}
      >
        <ContainerNavLg className='flex flex-column col-12 p-0 flex justify-content-center align-content-center'>
          <Button
            label='Home'
            aria-label='Menu'
            className='button mt-7'
            onClick={handleHome}
          />
          <Button
            label='History'
            aria-label='Menu'
            className='button mt-4'
            onClick={handleHistory}
          />
          <Button
            label='About'
            aria-label='Menu'
            className='button mt-4'
            onClick={handleAbout}
          />
          <Button
            label='Log out'
            aria-label='Menu'
            className='button mt-4'
            onClick={handleLogOut}
          />
          <div className='email text-center'>
            <strong>{email}</strong>
          </div>
        </ContainerNavLg>
      </Sidebar>
    </>
  )
}