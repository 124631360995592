import { Reducer } from "redux"
import { IRoleState } from "./types"

const INITIAL_STATE: IRoleState = {
  productId: '',
  featureId: '',
  groupId: '',
  idea: '',
  userStories: []
}

export const role: Reducer<IRoleState> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'LIST_ROLE_SUCCESS': {
      const { role } = action.payload
      return {
        ...state,
        ...role
      }
    }
    default: {
      return state
    }
  }
}