import { ContainerFooter, DividerUsg } from './styles'
import LogoDesign from '../../assets/logo-planorama-design.svg'
import LogoAiProduct from '../../assets/logo-ai-product-hive.svg'

export const Footer = () => {
  return (
    <ContainerFooter className='flex flex-column align-items-center justify-content-center'>
      <DividerUsg className='my-7 w-full' />
      <div className='sm:flex md:flex lg:flex xl:flex justify-content-around col-12'>
        <div className='flex flex-column align-items-center'>
          <div className='title'>powered by</div>
          <div className='mt-5 mb-3'>
            <a href='https://planorama.design' target={'_blank'}>
              <img src={LogoDesign} alt='Planorama Design' />
            </a>
          </div>
          <div className='by-design'>
            Time to Market, <br />
            Accelerated by Design
          </div>
        </div>

        <div className='flex flex-column mt-8 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 align-items-center'>
          <div className='title'>join our community</div>
          <div className='mt-5 mb-3'>
            <a href='https://c.aiproducthive.com/' target={'_blank'}>
              <img src={LogoAiProduct} alt='AI Product Hive' />
            </a>
          </div>
          <div className='by-design'>
            AI-focused community <br /> for product teams
          </div>
        </div>
      </div>
    </ContainerFooter>
  )
}