import styled from "styled-components"

export const ContainerHeader = styled.div`
  width: 100%;

  .usg {
    font-size: 3rem;
    margin-top: 64px;
  }

  .logo {
    width: 130px;
  }

  .button-outlined-usg {
    font-size:14px !important ;
  }
`