import styled from "styled-components"

export const Card = styled.div`
@media (max-width: 575px) {
  margin-bottom: 0.6rem ;
  min-width: 100%;
}

@media (min-width: 991px) {
  min-height: 200px;
}

@media (max-width: 990px) {
  min-height: 140px;
}

@media (max-width: 766px) {
  min-height: 150px;
}

  padding: 1rem;
  background-color: #F4F4F4;
  border-radius: 4px;
  font-size: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex ;
  flex-direction: column ;
  justify-content: space-between ;
  cursor: pointer;

  &:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  background-color: #ecebeb;
  }

  .btn-review {
    width: 100%;
    color: var(--green);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .txt-log {
    font-size: 0.7rem;
  }
`