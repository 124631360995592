import styled from "styled-components"

export const Content = styled.div`
  @media (min-width: 900px) {
    width: 850px;
  }

  @media (max-width: 899px) {
    width: 100%;
  }

  .input {
    margin-top: 2.5rem;
  }

  .error {
    font-size: 1rem;
    margin-top: 14px;
    color: #FF603B;
  }

  .character{
    position: absolute ;
    right: 0.7rem;
    text-align: right;
    bottom: 12px;
    font-size: 10px;
  }
`